<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="90px">
      <el-form-item label="工具名" prop="name">
        <el-input v-model="queryParams.name" placeholder="工具名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="工具描述" prop="name">
        <el-input v-model="queryParams.description" placeholder="描述，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getComfyUIToolInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="handleClickAddTool">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>新增</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 250px)" style="width: 100%;">
<!--      <el-table-column type="selection" width="55"></el-table-column>-->
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="code" label="工具码"></el-table-column>
      <el-table-column prop="name" label="工具名"></el-table-column>
      <el-table-column prop="description" label="工具描述" width="200"></el-table-column>
      <el-table-column prop="type" label="工具类型"></el-table-column>
      <el-table-column prop="cover" label="效果图" width="300">
        <template #default="scope">
          <div v-if="scope.row.cover"
               style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <el-image :src="scope.row.cover"
                      fit="contain"
                      class="try-on-result-image"
                      :zoom-rate="1.2"
                      :max-scale="7"
                      :min-scale="0.5"
                      preview-teleported
                      :preview-src-list="[scope.row.cover]">
            </el-image>
          </div>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="run_type" label="运行类型"></el-table-column>
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
<!--      <el-table-column prop="enable" label="是否可用" width="150">-->
<!--        <template #default="scope">-->
<!--          <el-tag v-if="scope.row.enable === 1" type="success">可用</el-tag>-->
<!--          <el-tag v-else type="warning">不可用</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="create_time" label="任务创建时间"/>
      <el-table-column prop="update_time" label="任务更新时间"/>
      <el-table-column fixed="right" label="操作" width="250">
        <template #default="scope">
          <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
            <el-button style="margin-bottom: 4px;margin-left: 12px" type="primary" size="small"
                       @click.prevent="handleClickEdit(scope.row)">编辑
            </el-button>
            <el-button style="margin-bottom: 4px;" type="success" size="small"
                       @click.prevent="handleClickAddComfyUITask(scope.row)">创建任务
            </el-button>
            <el-button style="margin-bottom: 4px;" type="danger" size="small"
                       @click.prevent="handleClickDelete(scope.row)">删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getComfyUIToolInfo"/>
    <!--编辑区域-->
    <div>
      <el-dialog title="ComfyUI工具"
                 v-model="dialogVisible"
                 width="60%"
                 :close-on-click-modal="false">
        <el-form
            :model="toolInfo"
            label-position="left"
            label-width="auto">
          <el-form-item label="工具码：" prop="code" required>
            <el-input v-model="toolInfo.code" :disabled="isUpdate" placeholder="请输入工具码">
            </el-input>
          </el-form-item>
          <el-form-item label="工具名：" prop="name" required>
            <el-input v-model="toolInfo.name"
                      placeholder="请输入工具名">
            </el-input>
          </el-form-item>
          <el-form-item label="工具描述：" prop="description">
            <el-input v-model="toolInfo.description"
                      placeholder="请输入工具描述">
            </el-input>
          </el-form-item>
          <el-form-item label="运行类型：" prop="run_type" required>
            <el-select v-model="toolInfo.run_type" placeholder="请选择运行类型">
              <el-option label="本地" value="local"></el-option>
              <el-option label="工作流" value="workflow"></el-option>
              <el-option label="第三方" value="third_api"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="封面图：" prop="upload" required>
            <oss-image-upload v-model="coverFileList" :limit="1"></oss-image-upload>
          </el-form-item>
          <el-form-item label="工具参数：" prop="param">
            <vue-json-editor style="width: 100%"
                             :mode="jsonMode"
                             :expandedOnStart="true"
                             :value="toolParam"
                             @json-change="onJsonInputChange">
            </vue-json-editor>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveEditInfo" :loading="submitLoading">保存</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <!--新建任务区域-->
    <div>
      <el-dialog title="创建任务"
                 v-model="addDialogVisible"
                 width="60%"
                 @before-close="beforeCloseAddTaskDialog"
                 :close-on-click-modal="false">
        <el-form
            :model="toolInfo"
            label-position="left"
            label-width="auto">
          <el-form-item label="工具码：" prop="code" required>
            <el-input v-model="toolInfo.code" disabled placeholder="请输入工具码">
            </el-input>
          </el-form-item>
          <el-form-item label="工具名：" prop="name" required>
            <el-input v-model="toolInfo.name" disabled
                      placeholder="请输入工具名">
            </el-input>
          </el-form-item>
          <el-form-item label="任务名：" prop="task_name" required>
            <el-input v-model="toolInfo.task_name"
                      placeholder="请输入任务名">
            </el-input>
          </el-form-item>
          <el-form-item label="上传输入图：" prop="upload" required>
            <el-upload style="margin-bottom: 4px;display: flex; align-items: center;"
                       ref="upload-model"
                       v-model:file-list="fileList"
                       :multiple="false"
                       :limit="1"
                       :on-remove="handleRemove"
                       :on-exceed="handleUploadExceed"
                       :before-upload="(file) => beforeUploadToOss(file)"
                       :http-request="(entity) => uploadToOss(entity)"
                       list-type="picture-card"
                       accept="image/*">
              <el-icon>
                <Plus/>
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="工具参数：" prop="param">
            <vue-json-editor style="width: 100%"
                             :mode="jsonMode"
                             :expandedOnStart="true"
                             :value="toolParam"
                             @json-change="onJsonInputChange">
            </vue-json-editor>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="addDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitTask" :loading="submitLoading">提交</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import VueJsonEditor from 'vue-json-editor';
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check, ArrowDown, Upload, Plus} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import imageCompression from "browser-image-compression";
import axios from "axios";
import OssImageUpload from "@/components/OssImageUpload.vue";

export default {
  name: "luma-account",
  components: {
    OssImageUpload,
    Pagination,
    CirclePlusFilled, Search, Download, Check, ArrowDown, Upload, Plus
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      jsonMode: 'code', // 编辑模式：'tree' 或 'code'
      editorOptions: {
        expandAll: true, // 默认展开所有节点
      },
      userInfoList: [],
      queryUserLoading: false,
      selectedIds: [],
      queryParams: {
        name: null,
        description: null,
      },
      tableLoading: false,
      tableData: [],
      loading: false,
      total: 0,
      toolParam: {},
      toolInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      isUpdate: false,
      dialogImageUrl: '',
      submitLoading: false,
      // 上传图片
      uploadModelImageLoading: false,
      ossReadUrl: null,
      ossUploadUrl: null,
      fileList: [],
      coverFileList: [], // 封面图片列表
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getComfyUIToolInfo();
  },
  methods: {
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      this.queryUserLoading = true;
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        this.queryUserLoading = false;
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.queryUserLoading = false;
        console.error(error);
      });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getComfyUIToolInfo() {
      this.tableLoading = true;
      axiosInstance.post('/api/comfyui/tool/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results;
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    handleClickAddTool() {
      this.isUpdate = false;
      this.dialogVisible = true;
      this.toolInfo = {};
      this.toolParam = {};
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/comfyui/tool/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    // 查看参数
    handleClickEdit(row) {
      this.isUpdate = true;
      this.submitLoading = false;
      this.toolInfo = row;
      try {
        this.toolParam = row.param ? JSON.parse(row.param) : {};
      } catch (error) {
        console.error('Invalid JSON format in param:', error);
        this.toolParam = {}; // 设置为默认值
      }
      console.log("handleClickEdit this.toolParam", this.toolParam)
      this.dialogVisible = true;
    },
    // 编辑任务参数
    saveEditInfo() {
      console.log("toolParam: ", this.toolParam)
      this.toolInfo.param = JSON.stringify(this.toolParam);
      console.log("toolInfo: ", this.toolInfo)
      if (!this.toolInfo.code) {
        this.$message.error('请输入工具编码');
        return;
      }
      if (!this.toolInfo.name) {
        this.$message.error('请输入工具名称');
        return;
      }
      if (!this.toolInfo.param) {
        this.$message.error('请输入工具参数');
        return;
      }
      this.submitLoading = true;
      axiosInstance.post('/api/comfyui/tool/update', {
        ...this.toolInfo
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('操作成功');
          this.dialogVisible = false;
          this.getComfyUIToolInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },
    // 提交新增任务
    submitTask() {
      if (!this.toolParam) {
        this.$message.error("参数不能为空")
        return
      }
      if (!this.toolParam.input_image_url) {
        this.$message.error("请输入图片地址")
        return
      }
      this.submitLoading = true;
      this.toolInfo.param = JSON.stringify(this.toolParam);
      console.log("toolInfo: ", this.toolInfo)
      axiosInstance.post('/api/comfyui/tool/submit_task', {
        ...this.toolInfo
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('操作成功');
          this.addDialogVisible = false;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },
    handleClickAddComfyUITask(row) {
      this.toolInfo = row;
      this.toolParam = JSON.parse(row.param);
      this.addDialogVisible = true;
    },
    handleClickDelete(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post('/api/comfyui/tool/delete', {
          id: row.id
        }).then(response => {
          if (response.data && response.data.code === 0) {
            this.$message.success('删除成功');
            this.getComfyUIToolInfo();
          } else {
            this.$message.error(response.data.msg);
          }
        }).catch(error => {
          console.error(error)
          this.$message.error('删除失败');
        });
      })
    },
    // 监听 input 事件，更新 toolParam 的值
    onJsonInputChange(newValue) {
      this.toolParam = newValue;
    },
    handleUploadExceed(files, fileList) {
      this.$message.warning('最多支持同时上传1个，请先删除已上传的文件');
    },
    handleRemove(uploadFile, uploadFiles) {
      this.toolParam.input_image_url = ''
    },
    async beforeUploadToOss(file) {
      console.log("beforeUploadToOss: ", file)
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        this.$message.error('只能上传图片');
        return false
      }
      // if (file.size / 1024 / 1024 > 5) {
      //   this.$message.error('文件大于5MB，请重新上传');
      //   return false
      // }
      // 检查文件大小
      if (file.size / 1024 < 5) {
        this.$message.error('图片小于5KB，请重新上传');
        return false;
      }
      // 使用FileReader加载图片以获取其尺寸
      const image = await this.loadImage(file);
      const {width, height} = image;
      // 检查分辨率和尺寸
      const maxLength = Math.max(width, height);
      const minLength = Math.min(width, height);
      if (width < 150 || height < 150 || maxLength > 4096 || minLength < 150) {
        this.$message.error('图片分辨率不满足要求，请重新上传');
        return false;
      }
      try {
        const response = await axiosInstance.post(`/api/oss/url/sign_put_and_get`, {
          filename: file.name,
          method: 'put',
        })
        console.log(response)
        if (response.data.code === 0) {
          this.ossUploadUrl = response.data.data.oss_url;
          this.ossReadUrl = response.data.data.read_oss_url;
        } else {
          this.$message.error('【' + file.name + '】无法上传，请稍后重试');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('【' + file.name + '】无法上传，请稍后重试');
        return false;
      }
      return true
    },
    // 图片上传到 OSS
    async uploadToOss(entity) {
      let file = entity.file;
      this.$message.info("【" + file.name + "】" + "正在上传，请稍候");
      console.log("压缩前: ", file.size / 1024 / 1024, "MB")
      // 如果文件大于 5MB，执行压缩
      if (file.size / 1024 / 1024 > 5) {
        try {
          const options = {
            maxSizeMB: 5,  // 压缩后的最大文件大小
            maxWidthOrHeight: 4096,  // 压缩时的最大宽高
            useWebWorker: true,  // 使用 Web Worker 进行压缩
            initialQuality: 0.75,  // 图片质量
          };
          console.log("正在压缩文件...");
          file = await imageCompression(file, options);
          console.log("压缩成功，压缩后大小 ", file.size / 1024 / 1024, "MB");
        } catch (error) {
          console.error("图片压缩失败: ", error);
          this.$message.error("图片压缩失败，请重试");
          return;
        }
      }
      // 使用 axios 进行上传
      axios.put(this.ossUploadUrl, file, {
        headers: {
          'Content-Type': 'image/*',
        }
      }).then(response => {
        console.info("【" + file.name + "】" + "上传成功", response);
        // this.toolInfo.input_url = this.ossReadUrl;
        this.toolParam.input_image_url = this.ossReadUrl;
        // this.fileList = [];
        this.$message.success("【" + file.name + "】" + "上传成功");
        console.log("上传成功 this.fileList", this.fileList)
      }).catch(error => {
        this.$message.error("【" + file.name + "】" + "上传失败，请重试");
        console.error(error);
      });
    },
    beforeCloseAddTaskDialog() {
      this.toolInfo = {}
      this.toolParam = {}
      this.fileList = []
    },
    // 加载图片并获取其宽高
    loadImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const img = new Image();
          img.onload = function () {
            resolve(img);
          };
          img.onerror = reject;
          img.src = e.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 1px solid transparent; /* 默认透明的边框 //border-radius: 20px; */
  width: 270px;
  height: 360px;
  margin: 10px;
  z-index: 1;
}
</style>