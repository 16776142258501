<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="100px">
      <el-form-item label="服务器名" prop="name">
        <el-input v-model="queryParams.name" placeholder="机器名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="机器状态" prop="status">
        <el-select v-model="queryParams.machine_status" style="width: 200px" placeholder="请选择机器状态" clearable>
          <el-option label="已关机" value="shutdown"></el-option>
          <el-option label="开机中" value="booting"></el-option>
          <el-option label="运行中" value="running"></el-option>
          <el-option label="已删除" value="removed"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务应用状态" prop="status">
        <el-select v-model="queryParams.status" style="width: 200px" placeholder="请选择服务应用状态" clearable>
          <el-option label="在线" value="online"></el-option>
          <el-option label="离线" value="offline"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="启用状态" prop="enable">
        <el-select v-model="queryParams.enable" style="width: 200px" placeholder="请选择启用状态" clearable>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="停用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getMachineInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <!--        <el-button type="success" @click="clickAddMachineInfo">-->
        <!--          <el-icon>-->
        <!--            <CirclePlus/>-->
        <!--          </el-icon>-->
        <!--          <span>新增机器</span>-->
        <!--        </el-button>-->
        <el-button type="success" :loading="refreshLoading" @click="clickRefreshMachineInfo">
          <el-icon>
            <Refresh/>
          </el-icon>
          <span>刷新服务器状态</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 220px)" style="width: 100%;">
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column prop="account_name" label="账号名称" width="150"></el-table-column>
      <el-table-column prop="id" label="服务器ID" width="100"></el-table-column>
      <el-table-column prop="instance_id" label="实例ID" width="150"></el-table-column>
      <el-table-column prop="name" label="服务器名" width="200"></el-table-column>
      <el-table-column prop="comfyui_url" label="ComfyUI HTTP地址" width="250"></el-table-column>
      <el-table-column prop="comfyui_ws_url" label="ComfyUI WS地址" width="250"></el-table-column>
      <el-table-column prop="queue_remaining" label="排队数量" width="100"></el-table-column>
      <el-table-column prop="status" label="机器状态" width="150">
        <template #default="scope">
          <el-tag :type="getMachineStatusTag(scope.row.machine_status)">
            {{ getMachineStatusText(scope.row.machine_status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="ComfyUI应用状态" width="150">
        <template #default="scope">
          <el-tag :type="getServiceStatusTag(scope.row.status)">{{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="schedule_type" label="调度类型" width="200">
        <template #default="scope">
          <el-tag v-if="scope.row.schedule_type === 'auto'" type="success">自动</el-tag>
          <el-tag v-if="scope.row.schedule_type === 'handle'" type="info">手动</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="tool_infos" label="绑定工作流" width="200">
        <template #default="scope">
          <div v-if="scope.row.tool_infos && scope.row.tool_infos.length > 0">
            <el-select v-model="scope.row.tool_infos" disabled multiple>
              <el-option
                  v-for="tool in allTools"
                  :key="tool.id"
                  :label="tool.name"
                  :value="tool.code"
              />
            </el-select>

            <!--            <el-tag v-for="(tool_info, index) in scope.row.tool_infos" :key="index" type="success">{{-->
            <!--                tool_info.tool_name-->
            <!--              }}-->
            <!--            </el-tag>-->
          </div>
          <div v-else>未绑定</div>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="是否可用" width="150">
        <template #default="scope">
          <el-tag v-if="scope.row.enable === 1" type="success">可用</el-tag>
          <el-tag v-else type="warning">不可用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" width="200" label="创建时间"></el-table-column>
      <el-table-column prop="update_time" width="200" label="更新时间"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template #default="scope">
          <div
              style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; flex-wrap: wrap">
            <el-button style="margin-bottom: 5px;" size="small" type="primary" @click.prevent="handleEdit(scope.row)">编辑
            </el-button>
            <el-button style="margin-bottom: 5px;" size="small" type="success"
                       :disabled="scope.row.machine_status === 'running'"
                       :loading="openMachineLoading"
                       @click.prevent="handleStartMachine(scope.row)">开机
            </el-button>
            <el-button style="margin-bottom: 5px;" size="small" type="warning"
                       :disabled="scope.row.machine_status === 'shutdown'"
                       :loading="stopMachineLoading"
                       @click.prevent="handleStopMachine(scope.row)">关机
            </el-button>
            <el-button style="margin-bottom: 5px;" type="danger" size="small" @click.prevent="handleDelete(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getMachineInfo"/>
    <!--新增or更新区域-->
    <div>
      <el-dialog v-if="dialogVisible" title="服务器信息"
                 v-model="dialogVisible"
                 width="600px"
                 :close-on-click-modal="false">
        <el-form
            :model="machineInfo"
            label-position="left"
            label-width="auto"
            style="width: 100%"
        >
          <el-form-item label="服务器名：" prop="name" required>
            <el-input v-model="machineInfo.name"
                      placeholder="请输入服务器名">
            </el-input>
          </el-form-item>
          <el-form-item label="ComfyUI Http URL：" prop="comfyui_url" required>
            <el-input v-model="machineInfo.comfyui_url"
                      placeholder="请输入comfyui http地址">
            </el-input>
          </el-form-item>
          <el-form-item label="ComfyUI WS URL：" prop="comfyui_ws_url" required>
            <el-input v-model="machineInfo.comfyui_ws_url"
                      placeholder="请输入comfyui ws地址">
            </el-input>
          </el-form-item>
          <el-form-item label="调度类型" prop="schedule_type" required>
            <el-select v-model="machineInfo.schedule_type" placeholder="请选择调度类型">
              <el-option label="自动" value="auto"></el-option>
              <el-option label="手动" value="handle"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定工作流" prop="tool_infos" required>
            <el-select v-model="machineInfo.tool_infos" multiple placeholder="选择comfyui工具">
              <el-option
                  v-for="tool in allTools"
                  :key="tool.id"
                  :label="tool.name"
                  :value="tool.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态：" prop="enable" required>
            <el-select v-model="machineInfo.enable">
              <el-option label="启用" :value="1"></el-option>
              <el-option label="停用" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input
                placeholder="可选，请输入备注"
                v-model="machineInfo.remark"/>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveEditInfo" :loading="submitLoading">保存</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, CirclePlus, Search, Download, Check, Refresh} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import {ElMessage} from "element-plus";

export default {
  name: "luma-account",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check, CirclePlus, Refresh
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      refreshLoading: false,
      userInfoList: [],
      allTools: [],
      queryUserLoading: false,
      openMachineLoading: false,
      stopMachineLoading: false,
      selectedIds: [],
      queryParams: {
        name: null,
        machine_status: null,
        status: null,
        enable: null,
        page_num: 1,
        page_size: 20,
      },
      tableLoading: false,
      tableData: [],
      loading: false,
      total: 0,
      machineInfo: null,
      dialogVisible: false,
      dialogImageUrl: '',
      submitLoading: false,
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getMachineInfo();
    this.fetchAllTools();
  },
  methods: {
    fetchAllTools() {
      axiosInstance.post('/api/comfyui/tool/page_query', {
        page_num: 1,
        page_size: 1000,
      }).then((response) => {
        if (response.data && response.data.code === 0) {
          this.allTools = response.data.data.results;
        }
      }).catch((error) => {
        this.$message.error('获取工具列表失败');
        console.error(error);
      });
    },
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      this.queryUserLoading = true;
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        this.queryUserLoading = false;
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.queryUserLoading = false;
        console.error(error);
      });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    getMachineInfo() {
      this.tableLoading = true;
      axiosInstance.post('/api/comfyui/machine/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results;
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    getServiceStatusTag(status) {
      switch (status) {
        case 'offline':
          return 'warning';
        case 'online':
          return 'success';
        default:
          return 'warning';
      }
    },
    getMachineStatusTag(status) {
      switch (status) {
        case 'booting':
          return 'primary';
        case 'running':
          return 'success';
        case 'shutdown':
          return 'info';
        case 'shutdown_backing_up':
          return 'primary';
        case 'removed':
          return 'danger';
        default:
          return 'warning';
      }
    },
    getMachineStatusText(status) {
      switch (status) {
        case 'booting':
          return '启动中';
        case 'running':
          return '运行中';
        case 'shutting_down':
          return '关机中';
        case 'shutdown':
          return '已关机';
        case 'shutdown_backing_up':
          return '关机备份中';
        case 'removed':
          return '已删除';
        default:
          return '未知';
      }
    },
    getEnableTag(status) {
      switch (status) {
        case 1:
          return 'success';
        case '1':
          return 'success';
        default:
          return 'warning';
      }
    },
    getEnableText(status) {
      switch (status) {
        case 1:
          return '启用';
        case '1':
          return '启用';
        default:
          return '停用';
      }
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    clickAddMachineInfo() {
      this.machineInfo = {
        id: null,
        name: null,
        comfyui_url: '',
        comfyui_ws_url: '',
        queue_remaining: 0,
        tool_infos: [],
        enable: 1,
        remark: '',
      };
      this.dialogVisible = true;
    },
    clickRefreshMachineInfo() {
      this.refreshLoading = true;
      axiosInstance.post('/api/comfyui/machine/refresh', {}).then(response => {
        this.refreshLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success("服务器状态刷新成功");
          this.getMachineInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.refreshLoading = false;
        console.error(error)
      });
    },
    saveEditInfo() {
      this.submitLoading = true;
      axiosInstance.post('/api/comfyui/machine/update', {
        ...this.machineInfo
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.dialogVisible = false;
          this.getMachineInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
      });
    },
    // 更新启用状态
    updateEnable(row) {
      axiosInstance.post('/api/comfyui/machine/update_enable', {
        id: row.id,
        enable: row.enable
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('更新成功');
          this.getMachineInfo()
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error);
        this.$message.error('更新失败');
      });
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/comfyui/machine/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
              this.getMachineInfo()
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    handleEdit(row) {
      this.machineInfo = {...row};
      this.dialogVisible = true;
    },
    handleStartMachine(row) {
      let that = this;
      this.$confirm('此操作将开启服务实例, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.openMachineLoading = true;
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post('/api/comfyui/machine/start_instance', {
          "instance_id": row.instance_id,
        }).then(res => {
          that.openMachineLoading = false;
          if (res.data.code === 0) {
            that.$message({
              type: 'success',
              message: '操作成功!'
            });
            that.getMachineInfo();
          } else {
            that.$message.error(res.data.msg);
          }
        }).catch(error => {
          this.openMachineLoading = false;
          that.$message({
            type: 'error',
            message: '操作失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    handleStopMachine(row) {
      let that = this;
      this.stopMachineLoading = true;
      this.$confirm('此操作将关闭服务实例, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post('/api/comfyui/machine/stop_instance', {
          "instance_id": row.instance_id,
        }).then(res => {
          this.stopMachineLoading = false;
          if (res.data.code === 0) {
            that.$message({
              type: 'success',
              message: '操作成功!'
            });
            that.getMachineInfo();
          } else {
            that.$message.error(res.data.msg);
          }
        }).catch(error => {
          this.stopMachineLoading = false;
          that.$message({
            type: 'error',
            message: '操作失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    handleDelete(row) {
      let that = this;
      this.$confirm('此操作将彻底删除记录, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post('/api/comfyui/machine/delete', {
          "id": row.id
        }).then(response => {
          that.$message({
            type: 'success',
            message: '删除成功!'
          });
          that.getMachineInfo();
        }).catch(error => {
          that.$message({
            type: 'error',
            message: '删除失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },

  }
  ,
}
;
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}


.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid transparent; /* 默认透明的边框 */
  border-radius: 20px;
  width: 180px;
  height: 240px;
  margin: 10px;
  z-index: 1;
}
</style>