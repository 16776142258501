<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="90px">
      <el-form-item label="任务ID" prop="id">
        <el-input v-model="queryParams.id" placeholder="任务ID" clearable></el-input>
      </el-form-item>
      <el-form-item label="任务来源" prop="task_source">
        <el-select v-model="queryParams.task_source" style="width: 150px" placeholder="请选择任务来源"
                   clearable>
          <el-option label="太屋" value="taiwu"></el-option>
          <el-option label="AI超市" value="iaimarket"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务名" prop="task_name">
        <el-input v-model="queryParams.task_name" placeholder="任务名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="任务状态" prop="task_status">
        <el-select v-model="queryParams.task_status" style="width: 150px" placeholder="请选择任务状态" clearable>
          <el-option label="未就绪" value="not_ready"></el-option>
          <el-option label="排队中" value="pending"></el-option>
          <el-option label="排队中（ComfyUI）" value="external_waiting"></el-option>
          <el-option label="执行中" value="doing"></el-option>
          <el-option label="执行完成" value="done_success"></el-option>
          <el-option label="执行失败" value="done_fail"></el-option>
          <el-option label="无需处理" value="not_need_handle"></el-option>
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="审核状态" prop="review_status">-->
      <!--        <el-select v-model="queryParams.review_status" style="width: 150px" placeholder="请选择任务审核状态" clearable>-->
      <!--          <el-option label="待审核" value="review_todo"></el-option>-->
      <!--          <el-option label="审核通过" value="review_pass"></el-option>-->
      <!--          <el-option label="审核不通过" value="review_fail"></el-option>-->
      <!--          <el-option label="审核完成" value="review_done"></el-option>-->
      <!--          <el-option label="无需处理" value="review_not_need_handle"></el-option>-->
      <!--          <el-option label="无法处理" value="review_not_handle"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="创建时间" prop="face_confidence_ge">
        <el-date-picker
            v-model="queryParams.create_time_ge"
            type="datetime"
            placeholder="请选择时间"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            date-format="MMM DD, YYYY"
            time-format="HH:mm"
        />
        至
        <el-date-picker
            v-model="queryParams.create_time_le"
            type="datetime"
            placeholder="请选择时间"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            date-format="MMM DD, YYYY"
            time-format="HH:mm"
        />
      </el-form-item>
      <!--      <el-form-item label="相似度大于" prop="face_confidence">-->
      <!--        <el-select v-model="queryParams.face_confidence_ge" style="width: 180px" placeholder="请选择人脸对比相似度"-->
      <!--                   clearable>-->
      <!--          <el-option label="10%及其以上" :value="10"></el-option>-->
      <!--          <el-option label="60%及其以上" :value="60"></el-option>-->
      <!--          <el-option label="65%及其以上" :value="65"></el-option>-->
      <!--          <el-option label="70%及其以上" :value="70"></el-option>-->
      <!--          <el-option label="75%及其以上" :value="75"></el-option>-->
      <!--          <el-option label="80%及其以上" :value="80"></el-option>-->
      <!--          <el-option label="85%及其以上" :value="85"></el-option>-->
      <!--          <el-option label="90%及其以上" :value="90"></el-option>-->
      <!--          <el-option label="95%及其以上" :value="95"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="相似度小于" prop="face_confidence">-->
      <!--        <el-select v-model="queryParams.face_confidence_le" style="width: 150px" placeholder="请选择人脸对比相似度"-->
      <!--                   clearable>-->
      <!--          <el-option label="10%以下" :value="10"></el-option>-->
      <!--          <el-option label="60%以下" :value="60"></el-option>-->
      <!--          <el-option label="65%以下" :value="65"></el-option>-->
      <!--          <el-option label="70%以下" :value="70"></el-option>-->
      <!--          <el-option label="75%以下" :value="75"></el-option>-->
      <!--          <el-option label="80%以下" :value="80"></el-option>-->
      <!--          <el-option label="85%以下" :value="85"></el-option>-->
      <!--          <el-option label="90%以下" :value="90"></el-option>-->
      <!--          <el-option label="95%以下" :value="95"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="promptId" prop="prompt_id">-->
      <!--        <el-input v-model="queryParams.prompt_id" placeholder="promptId，支持模糊查询" clearable></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="getComfyUITaskInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success"
                   :disabled="selectedIds === null || selectedIds.length === 0"
                   @click="clickBatchRestartMaterialInfo">
          <el-icon>
            <HelpFilled/>
          </el-icon>
          <span>批量执行</span>
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning"
                   :disabled="selectedIds === null || selectedIds.length === 0"
                   @click="clickBatchDeleteMaterialInfo">
          <el-icon>
            <Delete/>
          </el-icon>
          <span>批量删除</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableData" v-loading="tableLoading" height="calc(100vh - 250px)"
              @selection-change="handleSelectionChange" style="width: 100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="任务ID" width="80"></el-table-column>
      <el-table-column prop="machine_id" label="服务器ID" width="80"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="tool_code" label="工作流码" width="100"></el-table-column>
      <!--      <el-table-column prop="user_id" label="用户ID"></el-table-column>-->
      <el-table-column prop="task_source" label="任务来源" width="100"></el-table-column>
      <el-table-column prop="task_name" label="任务名" width="200"></el-table-column>
      <el-table-column prop="task_desc" label="任务描述"/>
      <!--      <el-table-column prop="review_status" label="审核状态" width="100">-->
      <!--        <template #default="scope">-->
      <!--          <el-tag :type="getReviewStatusTag(scope.row.review_status)">{{-->
      <!--              getReviewStatusText(scope.row.review_status)-->
      <!--            }}-->
      <!--          </el-tag>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="task_status" label="任务状态" width="100">
        <template #default="scope">
          <el-tag :type="getTaskStatusTag(scope.row.task_status)">{{
              getTaskStatusText(scope.row.task_status)
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="task_priority" label="任务优先级" width="80"/>
      <el-table-column width="500" label="输入">
        <template #default="scope">
          <div v-if="scope.row.input && scope.row.input.length > 0"
               style="display: flex; justify-content: center; align-items: center;">
            <el-button @click="inputPrevItem(scope.row)" :disabled="scope.row.inputIndex === 0">
              <el-icon>
                <ArrowLeft/>
              </el-icon>
            </el-button>
            <div class="preview-container">
              <div v-if="scope.row.input[scope.row.inputIndex].type === 'image'">
                <el-image :src="scope.row.input[scope.row.inputIndex].url"
                          fit="contain"
                          class="system-result-image"
                          :zoom-rate="1.2"
                          :max-scale="7"
                          :min-scale="0.5"
                          preview-teleported
                          :preview-src-list="scope.row.input.filter(item => item.type === 'image').map(item => item.url)"
                          @click="openPreview(scope.row.input, scope.row.inputIndex)">
                </el-image>
              </div>
              <div v-else-if="scope.row.input[scope.row.inputIndex].type === 'video'">
                <video class="system-result-image" :src="scope.row.input[scope.row.inputIndex].url" controls></video>
              </div>
            </div>
            <el-button @click="inputNextItem(scope.row)" :disabled="scope.row.inputIndex === scope.row.input.length - 1">
              <el-icon>
                <ArrowRight/>
              </el-icon>
            </el-button>
          </div>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <el-table-column width="500" label="输出">
        <template #default="scope">
          <div v-if="scope.row.output && scope.row.output.length > 0"
               style="display: flex; justify-content: center; align-items: center;">
            <el-button @click="outputPrevItem(scope.row)" :disabled="scope.row.outputIndex === 0">
              <el-icon>
                <ArrowLeft/>
              </el-icon>
            </el-button>
            <div class="preview-container">
              <div v-if="scope.row.output[scope.row.outputIndex].type === 'image'">
                <el-image :src="scope.row.output[scope.row.outputIndex].url"
                          fit="contain"
                          class="system-result-image"
                          :zoom-rate="1.2"
                          :max-scale="7"
                          :min-scale="0.5"
                          preview-teleported
                          :preview-src-list="scope.row.output.filter(item => item.type === 'image').map(item => item.url)"
                          @click="openPreview(scope.row.output, scope.row.outputIndex)">
                </el-image>
              </div>
              <div v-else-if="scope.row.output[scope.row.outputIndex].type === 'video'">
                <video class="system-result-image" :src="scope.row.output[scope.row.outputIndex].url" controls></video>
              </div>
            </div>
            <el-button @click="outputNextItem(scope.row)" :disabled="scope.row.outputIndex === scope.row.output.length - 1">
              <el-icon>
                <ArrowRight/>
              </el-icon>
            </el-button>
          </div>
          <div v-else style="text-align: center; color: #999999">
            无
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="face_confidence" label="人脸对比相似度" width="150"/>-->
      <!--      <el-table-column prop="prompt_id" label="promptId" width="150"/>-->
      <el-table-column prop="callback_result" label="回调结果" width="150">
        <template #default="scope">
          <el-input v-if="scope.row.meta_data" v-model="scope.row.meta_data.callback_result" style="width: 250px"
                    :rows="3"
                    type="textarea"></el-input>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="client_id" label="client_id" width="150"/>-->
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="start_time" width="120" label="任务开始时间"/>
      <el-table-column prop="end_time" width="120" label="任务结束时间"/>
      <el-table-column prop="duration" width="120" label="任务耗时/秒">
        <template #default="scope">
          <el-tag>{{ handleShowTaskTimeDuration(scope.row) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" width="120" label="创建时间"/>
      <el-table-column prop="update_time" width="120" label="更新时间"/>
      <el-table-column fixed="right" label="操作" width="250">
        <template #default="scope">
          <div style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap">
            <el-button size="small" style="margin-bottom: 4px;margin-left: 12px" type="info"
                       @click.prevent="handleLookParam(scope.row)">查看参数
            </el-button>
            <el-button size="small" style="margin-bottom: 4px;" type="primary"
                       @click.prevent="handleReExecute(scope.row)">
              重新执行
            </el-button>
            <el-button size="small" style="margin-bottom: 4px;" type="warning"
                       @click.prevent="handleClickDelete(scope.row)">
              删除任务
            </el-button>
            <el-button size="small" style="margin-bottom: 4px;" type="info"
                       @click.prevent="handleClickDelete(scope.row)">
              无需处理
            </el-button>
            <!--            <el-button size="small" v-if="scope.row.tool_code === 'face_optimization' && scope.row.output_url"-->
            <!--                       style="margin-bottom: 4px;" type="primary"-->
            <!--                       @click.prevent="handleSecondFaceEnhance(scope.row)">-->
            <!--              二次优化-->
            <!--            </el-button>-->
            <!--            <el-button size="small" v-if="scope.row.tool_code === 'face_optimization' && scope.row.output_url"-->
            <!--                       style="margin-bottom: 4px;" type="danger"-->
            <!--                       @click.prevent="deleteSecondFaceEnhance(scope.row)">-->
            <!--              删除二次优化-->
            <!--            </el-button>-->
            <!--            <el-dropdown>-->
            <!--              <el-button size="small" type="success" style="margin-left: 12px; margin-bottom: 2px;">-->
            <!--                人工审核-->
            <!--                <el-icon class="el-icon&#45;&#45;right">-->
            <!--                  <arrow-down/>-->
            <!--                </el-icon>-->
            <!--              </el-button>-->
            <!--              <template #dropdown>-->
            <!--                <el-dropdown-menu>-->
            <!--                  <el-dropdown-item @click="handleUpdateReviewStatus(scope.row, 'review_todo')">待审核-->
            <!--                  </el-dropdown-item>-->
            <!--                  <el-dropdown-item @click="handleUpdateReviewStatus(scope.row, 'review_pass')">审核通过-->
            <!--                  </el-dropdown-item>-->
            <!--                  <el-dropdown-item @click="handleUpdateReviewStatus(scope.row, 'review_fail')">审核不通过-->
            <!--                  </el-dropdown-item>-->
            <!--                  <el-dropdown-item @click="handleUpdateReviewStatus(scope.row, 'review_done')">处理完成-->
            <!--                  </el-dropdown-item>-->
            <!--                  <el-dropdown-item @click="handleUpdateReviewStatus(scope.row, 'review_not_need_handle')">无需处理-->
            <!--                  </el-dropdown-item>-->
            <!--                  <el-dropdown-item @click="handleUpdateReviewStatus(scope.row, 'review_not_handle')">无法处理-->
            <!--                  </el-dropdown-item>-->
            <!--                </el-dropdown-menu>-->
            <!--              </template>-->
            <!--            </el-dropdown>-->

            <el-dropdown>
              <el-button size="small" type="primary" style="margin-left: 12px; margin-bottom: 2px;">
                更多操作
                <el-icon class="el-icon--right">
                  <arrow-down/>
                </el-icon>
              </el-button>
              <template #dropdown>
                <!--            <el-button size="small" style="margin-bottom: 4px;" type="primary"-->
                <!--                       @click.prevent="handleNoWatermarkInputImage(scope.row)">-->
                <!--              输入图去水印-->
                <!--            </el-button>-->
                <el-dropdown-menu>
                  <el-dropdown-item @click="handleSecondFaceEnhance(scope.row)">二次优化</el-dropdown-item>
                  <el-dropdown-item @click="handleCompareFace(scope.row)">输入/输出人脸对比</el-dropdown-item>
                  <el-dropdown-item @click="handleBeautifyFace(scope.row, 'input')">输入图智能美肤</el-dropdown-item>
                  <el-dropdown-item @click="handleBeautifyFace(scope.row, 'output')">输出图智能美肤</el-dropdown-item>
                  <el-dropdown-item @click="handleAutoCutImage(scope.row, 'input')">输入图裁剪</el-dropdown-item>
                  <el-dropdown-item @click="handleAutoCutImage(scope.row, 'output')">输出图裁剪</el-dropdown-item>
                  <el-upload ref="upload-model" style="margin-left: 10px"
                             v-model:file-list="fileList"
                             :multiple="false"
                             :limit="1"
                             :show-file-list="false"
                             :on-exceed="handleUploadExceed"
                             :before-upload="(file) => beforeUploadToOss(file)"
                             :http-request="(entity) => uploadToOss(entity, scope.row)"
                             list-type="text"
                             accept="image/*">
                    <el-button size="small" type="primary" :loading="uploadModelImageLoading">
                      输入图替换
                    </el-button>
                  </el-upload>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.page_num"
                v-model:limit="queryParams.page_size" @pagination="getComfyUITaskInfo"/>
    <!--编辑参数区域-->
    <div>
      <el-dialog title="任务参数信息"
                 v-model="dialogVisible"
                 width="60%"
                 :close-on-click-modal="false">
        <el-form
            :model="taskInfo"
            label-position="left"
            label-width="auto">
          <el-form-item label="任务名：" prop="name" required>
            <el-input v-model="taskInfo.task_name"
                      placeholder="请输入任务名">
            </el-input>
          </el-form-item>
          <el-form-item label="工作流编码：" prop="tool_code" required>
            <el-input v-model="taskInfo.tool_code"
                      placeholder="请输入工作流编码">
            </el-input>
          </el-form-item>
          <el-form-item label="任务参数：" prop="task_param">
            <vue-json-editor style="width: 800px"
                             :mode="jsonMode"
                             :expandedOnStart="true"
                             :value="taskParam"
                             @json-save="onJsonInputSave"
                             @json-change="onJsonInputChange">
            </vue-json-editor>
          </el-form-item>
          <el-form-item label="任务输出：" prop="task_result" required>
            <el-input v-model="taskInfo.task_result" type="textarea" :rows="4"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveEditInfo" :loading="submitLoading">保存</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import VueJsonEditor from 'vue-json-editor';
import axiosInstance from '@/api/axiosInstance';
import {
  CirclePlusFilled,
  Search,
  Download,
  Check,
  ArrowDown,
  Delete,
  HelpFilled,
  ArrowLeft,
  ArrowRight
} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import imageCompression from "browser-image-compression";
import axios from "axios";

export default {
  name: "luma-account",
  components: {
    Delete,
    Pagination,
    CirclePlusFilled, Search, Download, Check, ArrowDown, HelpFilled, ArrowLeft, ArrowRight
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    return {
      currentIndex: 0,
      taskParam: {},
      jsonMode: 'code', // 编辑模式：'tree' 或 'code'
      editorOptions: {
        expandAll: true, // 默认展开所有节点
      },
      userInfoList: [],
      queryUserLoading: false,
      selectedIds: [],
      queryParams: {
        id: null,
        task_source: null,
        task_name: null,
        task_status: null,
        review_status: null,
        prompt_id: null,
        page_num: 1,
        page_size: 10,
        create_time_ge: null, // 创建时间大于等于
        create_time_le: null, // 创建时间小于等于
        face_confidence_ge: null, // 相似度大于等于
        face_confidence_le: null, // 相似度小于等于
      },
      tableLoading: false,
      tableData: [],
      loading: false,
      total: 0,
      taskInfo: {},
      dialogVisible: false,
      dialogImageUrl: '',
      submitLoading: false,
      // 上传图片
      uploadModelImageLoading: false,
      ossReadUrl: null,
      ossUploadUrl: null,
      fileList: [],
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId;
    }
    if (this.$route.query.uid) {
      this.queryParams.userId = this.$route.query.uid;
    }
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.queryUserInfo(null);
    }
    this.getComfyUITaskInfo();
  },
  methods: {
    inputPrevItem(row) {
      if (row.inputIndex > 0) {
        row.inputIndex--;
      }
    },
    inputNextItem(row) {
      if (row.inputIndex < row.input.length - 1) {
        row.inputIndex++;
      }
    },
    outputPrevItem(row) {
      if (row.outputIndex > 0) {
        row.outputIndex--;
      }
    },
    outputNextItem(row) {
      if (row.outputIndex < row.output.length - 1) {
        row.outputIndex++;
      }
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    // 批量删除任务
    clickBatchDeleteMaterialInfo() {
      let that = this;
      this.$confirm(`此操作将删除${this.selectedIds.length}条记录, 是否继续?'`, '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post(`/api/comfyui/task/delete`, {
          id: this.selectedIds
        }).then(response => {
          that.$message({
            type: 'success',
            message: '删除成功!'
          });
          // 删除成功后，刷新列表
          that.getComfyUITaskInfo();
        }).catch(error => {
          that.$message({
            type: 'error',
            message: '删除失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },
    // 批量重启
    clickBatchRestartMaterialInfo() {
      let that = this;
      this.$confirm(`此操作将重新执行${this.selectedIds.length}条记录, 是否继续?'`, '确认执行', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post(`/api/comfyui/task/re_execute`, {
          task_id: this.selectedIds
        }).then(response => {
          that.$message({
            type: 'success',
            message: '操作成功，请稍后查看任务状态'
          });
          // 刷新列表
          that.getComfyUITaskInfo();
        }).catch(error => {
          that.$message({
            type: 'error',
            message: '操作失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    queryUserInfo(username) {
      console.log("queryUserInfo: ", username)
      this.queryUserLoading = true;
      axiosInstance.post('/api/user/page_query', {
        "username": username || "",
      }).then(response => {
        this.queryUserLoading = false;
        if (response.data && response.data.code === 0) {
          this.userInfoList = response.data.data.results;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.queryUserLoading = false;
        console.error(error);
      });
    },
    getComfyUITaskInfo() {
      this.tableLoading = true;
      axiosInstance.post('/api/comfyui/task/page_query', {
        ...this.queryParams
      }).then(response => {
        this.tableLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.total = data.total;
          this.tableData = data.results || []
          // 每个data补充 inputIndex 和 outputIndex 字段
          this.tableData.forEach(item => {
            item.inputIndex = 0;
            item.outputIndex = 0;
          });
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.tableLoading = false;
        console.error(error)
      });
    },
    getTaskStatusText(status) {
      switch (status) {
        case 'not_ready':
          return '未就绪';
        case 'pending':
          return '排队中';
        case 'external_waiting':
          return 'ComfyUI排队中';
        case 'doing':
          return '执行中';
        case 'done_success':
          return '执行完成';
        case 'done_fail':
          return '执行失败';
        default:
          return '执行失败';
      }
    },
    getTaskStatusTag(status) {
      switch (status) {
        case 'not_ready':
          return 'info';
        case 'pending':
          return 'info';
        case 'external_waiting':
          return 'info';
        case 'doing':
          return 'primary';
        case 'done_success':
          return 'success';
        case 'done_fail':
          return 'error';
        default:
          return 'warning';
      }
    },
    getReviewStatusTag(status) {
      switch (status) {
        case 'review_todo':
          return 'info';
        case 'review_fail':
          return 'warning';
        case 'review_pass':
          return 'success';
        case 'review_done':
          return 'primary';
        case 'review_not_handle':
          return 'danger';
        default:
          return 'warning';
      }
    },
    getReviewStatusText(status) {
      switch (status) {
        case 'review_todo':
          return '待审核';
        case 'review_fail':
          return '审核不通过';
        case 'review_pass':
          return '审核通过';
        case 'review_done':
          return '审核完成';
        case 'review_not_handle':
          return '无法处理';
        case 'review_not_need_handle':
          return '无需处理';
        default:
          return '未知状态';
      }
    },
    // 编辑备注
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/comfyui/task/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    // 查看参数
    handleLookParam(row) {
      this.submitLoading = false;
      this.taskInfo = row;
      try {
        this.taskParam = row.task_param ? JSON.parse(row.task_param) : {};
      } catch (error) {
        console.error('Invalid JSON format in task_param:', error);
        this.taskParam = {}; // 设置为默认值
      }
      console.log("handleLookParam this.taskParam", this.taskParam)
      this.dialogVisible = true;
    },
    handleClickDelete(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post('/api/comfyui/task/delete', {
          id: row.id
        }).then(response => {
          if (response.data && response.data.code === 0) {
            this.$message.success('删除成功');
            this.getComfyUITaskInfo();
          } else {
            this.$message.error(response.data.msg);
          }
        }).catch(error => {
          console.error(error)
        });
      })
    },
    // 重新执行
    handleReExecute(row) {
      // this.$confirm('确定要重新执行该任务吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      axiosInstance.post('/api/comfyui/task/re_execute', {
        task_id: row.id
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('任务已重新执行');
          // this.getComfyUITaskInfo();
          row.task_status = response.data.data.task_status;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 任务执行时间
    handleShowTaskTimeDuration(row) {
      if (row.start_time && row.end_time) {
        const start = new Date(row.start_time).getTime();
        const end = new Date(row.end_time).getTime();
        const duration = end > start ? (end - start) / 1000 : 0;
        return duration + '秒'
      } else {
        return '无'
      }
    },
    // 输入图去水印
    handleNoWatermarkInputImage(row) {
      // this.$confirm('确定要裁剪图片吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      this.$message.info('去水印已提交，请稍后');
      axiosInstance.post('/api/comfyui/task/no_watermark_input_image', {
        task_id: row.id
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('去水印完成');
          row.input_url = response.data.data;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 太屋自动裁剪图片
    handleAutoCutImage(row, type) {
      // this.$confirm('确定要裁剪图片吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      let image_url;
      if (type === 'input') {
        image_url = row.input?.[0]?.image_url || null;
      } else {
        image_url = row.output?.[0]?.image_url || null;
      }
      if (!image_url) {
        this.$message.error('缺少图片，无法进行自动裁剪');
        return
      }
      axiosInstance.post('/api/comfyui/task/auto_cut_image', {
        task_id: row.id,
        image_url: image_url,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('裁剪完成');
          if (type === 'input') {
            row.input[0].image_url = response.data.data
          } else {
            row.output[0].image_url = response.data.data
          }
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 更新审核状态
    handleUpdateReviewStatus(row, reviewStatus) {
      // this.$confirm('确定要修改审核状态吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      axiosInstance.post('/api/comfyui/task/update_review_status', {
        task_id: row.id,
        review_status: reviewStatus,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('修改完成');
          row.review_status = reviewStatus;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
        this.$message.error(error.message);
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 二次优化
    handleSecondFaceEnhance(row) {
      if (row.tool_code !== 'face_optimization') {
        this.$message.error('该任务不支持二次优化');
        return
      }
      let image_url = row.output?.[0]?.image_url || null;
      if (!image_url) {
        this.$message.error('缺少输出，无法进行二次优化');
        return
      }
      // this.$confirm('确定要执行二次优化任务吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      axiosInstance.post('/api/comfyui/demo/face_optimization/add_second_task', {
        task_id: row.id,
        image_url: image_url,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('任务已添加');
          // this.getComfyUITaskInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 删除二次优化
    deleteSecondFaceEnhance(row) {
      // if (!row.output_url) {
      //   this.$message.error('该任务没有输出结果，无法进行二次优化');
      //   return
      // }
      // this.$confirm('确定要执行二次优化任务吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      axiosInstance.post('/api/comfyui/demo/face_optimization/delete_second_task', {
        task_name: row.task_name,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('任务已删除');
          // this.getComfyUITaskInfo();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 智能美肤
    handleBeautifyFace(row, type) {
      // if (!row.output_url) {
      //   this.$message.error('该任务没有输出结果，无法进行二次优化');
      //   return
      // }
      // this.$confirm('确定要执行智能美肤任务吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      let image_url;
      if (type === 'input') {
        image_url = row.input?.[0]?.image_url || null;
      } else {
        image_url = row.output?.[0]?.image_url || null;
      }
      if (!image_url) {
        this.$message.error('缺少图片，无法进行智能美肤');
        return
      }
      axiosInstance.post('/api/comfyui/task/beautify_face', {
        task_id: row.id,
        image_url: image_url,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('智能美肤执行完成');
          if (type === 'input') {
            row.input[0].image_url = response.data.data
          } else {
            row.output[0].image_url = response.data.data
          }
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 人脸相似度对比
    handleCompareFace(row) {
      // if (!row.output_url) {
      //   this.$message.error('该任务没有输出结果，无法进行人脸相似度对比');
      //   return
      // }
      // this.$confirm('确定要执行人脸相似度对比任务吗？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      if (row.input && row.input.length > 0 && row.output && row.output.length > 0) {
        if (row.input[0].image_url && row.output[0].image_url) {
          // pass
        } else {
          this.$message.error('该任务没有输入或输出结果，无法进行人脸相似度对比');
          return
        }
      } else {
        this.$message.error('该任务没有输入或输出结果，无法进行人脸相似度对比');
        return
      }
      axiosInstance.post('/api/comfyui/task/compare_face', {
        task_id: row.id,
        one_image_url: row.input[0].image_url,
        two_image_url: row.output[0].image_url,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success('人脸相似度对比执行完成');
          row.face_confidence = response.data.data
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消'
      //   });
      // });
    },
    // 编辑任务参数
    saveEditInfo() {
      this.submitLoading = true;
      console.log("taskParam: ", this.taskParam)
      axiosInstance.post('/api/comfyui/task/update_task_param', {
        task_id: this.taskInfo.id,
        tool_code: this.taskInfo.tool_code,
        task_param: JSON.stringify(this.taskParam),
      }).then(response => {
        this.submitLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success('任务参数更新完成');
          this.taskInfo.task_param = JSON.stringify(this.taskParam);
          this.dialogVisible = false;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.submitLoading = false;
        console.error(error)
        this.$message.error(error.message);
      });
    },
    // 监听 input 事件，更新 taskParam 的值
    onJsonInputChange(newValue) {
      // console.log("onJsonInputChange", newValue)
      this.taskParam = newValue;
      // console.log("onJsonInputChange this.taskParam", this.taskParam)
      // this.taskParam = newValue;
    },
    handleUploadExceed(files, fileList) {
      this.$message.warning('最多支持同时上传1个，请先删除已上传的文件');
    },
    async beforeUploadToOss(file) {
      console.log("beforeUploadToOss: ", file)
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        this.$message.error('只能上传图片');
        return false
      }
      // if (file.size / 1024 / 1024 > 5) {
      //   this.$message.error('文件大于5MB，请重新上传');
      //   return false
      // }
      // 检查文件大小
      if (file.size / 1024 < 5) {
        this.$message.error('图片小于5KB，请重新上传');
        return false;
      }
      // 使用FileReader加载图片以获取其尺寸
      const image = await this.loadImage(file);
      const {width, height} = image;
      // 检查分辨率和尺寸
      const maxLength = Math.max(width, height);
      const minLength = Math.min(width, height);
      if (width < 150 || height < 150 || maxLength > 4096 || minLength < 150) {
        this.$message.error('图片分辨率不满足要求，请重新上传');
        return false;
      }
      try {
        const response = await axiosInstance.post(`/api/oss/url/sign_put_and_get`, {
          filename: file.name,
          method: 'put',
        })
        console.log(response)
        if (response.data.code === 0) {
          this.ossUploadUrl = response.data.data.oss_url;
          this.ossReadUrl = response.data.data.read_oss_url;
        } else {
          this.$message.error('【' + file.name + '】无法上传，请稍后重试');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('【' + file.name + '】无法上传，请稍后重试');
        return false;
      }
      return true
    },
    // 图片上传到 OSS
    async uploadToOss(entity, row) {
      let file = entity.file;
      try {
        this.taskInfo = row;
        this.taskParam = row.task_param ? JSON.parse(row.task_param) : {};
      } catch (error) {
        console.error('Invalid JSON format in task_param:', error);
        this.taskParam = {}; // 设置为默认值
      }
      console.log("uploadToOss entity:", entity);
      console.log("uploadToOss row:", this.taskInfo);
      console.log("uploadToOss taskParam:", this.taskParam);
      this.$message.info("【" + file.name + "】" + "正在上传，请稍候");
      console.log("压缩前: ", file.size / 1024 / 1024, "MB")
      // 如果文件大于 5MB，执行压缩
      if (file.size / 1024 / 1024 > 5) {
        try {
          const options = {
            maxSizeMB: 5,  // 压缩后的最大文件大小
            maxWidthOrHeight: 4096,  // 压缩时的最大宽高
            useWebWorker: true,  // 使用 Web Worker 进行压缩
            initialQuality: 0.75,  // 图片质量
          };
          console.log("正在压缩文件...");
          file = await imageCompression(file, options);
          console.log("压缩成功，压缩后大小 ", file.size / 1024 / 1024, "MB");
        } catch (error) {
          console.error("图片压缩失败: ", error);
          this.$message.error("图片压缩失败，请重试");
          return;
        }
      }
      // 使用 axios 进行上传
      axios.put(this.ossUploadUrl, file, {
        headers: {
          'Content-Type': 'image/*',
        }
      }).then(response => {
        console.info("【" + file.name + "】" + "上传成功", response);
        this.$message.success("【" + file.name + "】" + "上传成功");
        row.input = [
          {
            "image_url": this.ossReadUrl,
          }
        ];
        this.fileList = [];
        // 更新输入参数
        this.taskParam.input_image_url = this.ossReadUrl;
        this.saveEditInfo();
      }).catch(error => {
        this.$message.error("【" + file.name + "】" + "上传失败，请重试");
        console.error(error);
      });
    },
    // 加载图片并获取其宽高
    loadImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const img = new Image();
          img.onload = function () {
            resolve(img);
          };
          img.onerror = reject;
          img.src = e.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

::v-deep .el-table th {
  text-align: center; /* 设置所有表头单元格的文本居中 */
}

.preview-container {
  width: 270px;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.system-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 1px solid transparent;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  z-index: 1;
}
</style>