<template>
  <div>
    <el-upload
        :file-list="internalFileList"
        :multiple="multiple"
        :limit="limit"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"
        :before-upload="beforeUpload"
        :http-request="uploadFile"
        list-type="picture-card"
        accept="image/*"
        class="oss-image-upload"
    >
      <el-icon>
        <Plus/>
      </el-icon>
    </el-upload>

    <el-dialog v-model="previewVisible" title="图片预览">
      <img :src="previewImage" class="preview-image"/>
    </el-dialog>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression'
import {Plus} from '@element-plus/icons-vue';
import axiosInstance from '@/api/axiosInstance';

export default {
  name: 'OssImageUpload',
  components: {
    Plus
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    },
    multiple: {
      type: Boolean,
      default: false
    },
    compressionThreshold: {
      type: Number,
      default: 5, // 默认5MB
      validator: value => value >= 0
    }
  },

  data() {
    return {
      internalFileList: [],
      previewVisible: false,
      previewImage: ''
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.internalFileList = this.normalizeFileList(newVal)
      }
    }
  },

  methods: {
    normalizeFileList(urls) {
      return urls.map(url => ({
        uid: url, // 使用url作为唯一标识
        name: url.split('?')[0].split('/').pop(),
        url: url,
        status: 'success'
      }))
    },

    handlePreview(file) {
      this.previewImage = file.url
      this.previewVisible = true
    },

    handleRemove(file) {
      this.internalFileList = this.internalFileList.filter(f => f.url !== file.url)
      this.emitUpdate()
    },

    emitUpdate() {
      this.$emit('input', this.internalFileList.map(f => f.url))
    },

    async beforeUpload(file) {
      // 类型验证
      if (!file.type.startsWith('image/')) {
        this.$message.error('只能上传图片文件')
        return false
      }

      try {
        const res = await axiosInstance.post('/api/oss/url/sign_put_and_get', {
          filename: file.name,
          method: 'put',
        })
        if (res.data.code === 0) {
          file.ossUrl = res.data.data.oss_url
          file.readUrl = res.data.data.read_oss_url
          return true
        } else {
          this.$message.error('【' + file.name + '】无法上传，请稍后重试');
          return false;
        }
      } catch (error) {
        this.$message.error('获取上传凭证失败')
        return false
      }
    },

    async uploadFile(entity) {
      const file = entity.file
      try {
        // 压缩处理（超过5MB自动压缩）
        let processedFile = file
        if (this.compressionThreshold > 0 && file.size > this.compressionThreshold * 1024 * 1024) {
          processedFile = await imageCompression(file, {
            maxSizeMB: this.compressionThreshold,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          })
        }

        // 上传到OSS
        await this.$axios.put(file.ossUrl, processedFile, {
          headers: {
            'Content-Type': 'image/*',
          }
        })

        // 添加到文件列表
        this.internalFileList = [
          ...this.internalFileList,
          {
            uid: file.uid,
            name: file.name,
            url: file.readUrl,
            status: 'success'
          }
        ]

        this.emitUpdate()
        this.$emit('upload-success', file.readUrl)
        this.$message.success('上传成功')
      } catch (error) {
        this.$message.error('上传失败')
        console.error('Upload error:', error)
      }
    },

    handleExceed() {
      this.$message.warning(`最多只能上传 ${this.limit} 个文件`)
    }
  }
}
</script>

<style scoped>
.preview-image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.oss-image-upload >>> .el-upload-list__item {
  transition: all 0.3s;
}
</style>