import {createApp} from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入 Element Plus 样式
import './assets/tailwind.css';       // 引入 Tailwind CSS 样式
import axios from 'axios';
import router from './router';
import store from './store';
import VueJsonEditor from 'vue-json-editor';


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);

app.use(router);
app.use(store);
app.use(ElementPlus);
// 全局注册 vue-json-editor
app.component('VueJsonEditor', VueJsonEditor);
// 全局错误处理
app.config.errorHandler = (err, vm, info) => {
    console.error(err);
    console.info(info);
    // 可以在这里显示一个友好的错误提示或者跳转到一个错误页面
    // 比如使用Element Plus的Message组件
    // app.config.globalProperties.$message.error(`发生错误：${err}`);
};

// 全局捕获未处理的Promise拒绝
window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled rejection: ', event);
    // console.error(`Unhandled rejection: ${event}`);
    //app.config.globalProperties.$message.error(`未处理的Promise拒绝：${event}`);
    event.preventDefault(); // 防止默认处理（例如控制台的错误日志）
});

app.mount('#app');