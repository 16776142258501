<template>
  <div>
    <!--    <div class="ai-try-on-banner">-->
    <!--      <div class="ai-try-on-banner-content">-->
    <!--        <div class="ai-try-on-banner-title">通用OCR识别</div>-->
    <!--        <div class="ai-try-on-banner-info">-->
    <!--          针对多场景、多业务的通用OCR识别，基于深度学习技术，支持图片文字识别、PDF内容识别、发票内容识别等常见需求，还可返回文字的位置信息、字符内容等-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="body-container">
      <!--      <div class="function-list-bg">-->
      <!--        <div class="function-container">-->
      <!--          <h2>AI一键换装</h2>-->
      <!--          <div class="function-list-body">-->
      <!--            <div class="function-item-container" v-for="(item, index) in functionInfoList" :key="index">-->
      <!--              <feature-icon height="64" width="64"></feature-icon>-->
      <!--              <div class="function-item-content">-->
      <!--                <h3>{{ item.title }}</h3>-->
      <!--                <p>{{ item.info }}</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="demo-show-container">
        <h2>AI一键换装</h2>
        <div class="show-container">
          <!--预览区域-->
          <div class="title-icon-box" style="margin-top: 20px">
            <preview-icon :height="32" :width="32"></preview-icon>
            <h2 class="font-bold">功能使用</h2>
          </div>
          <div class="preview-container">
            <!-- 左边区域 -->
            <div class="left">
              <h4 class="mb-2">模特</h4>
              <div v-if="modelImageUrl" class="image-container">
                <el-image :src="modelImageUrl"
                          fit="contain"
                          :zoom-rate="1.2"
                          :max-scale="7"
                          :min-scale="0.2"
                          :preview-src-list="[modelImageUrl]"
                          class="vertical-image-box">
                </el-image>
                <!-- 删除图标 -->
                <el-icon
                    @click="removeImage('model')"
                    class="delete-icon">
                  <Delete/>
                </el-icon>
              </div>
              <div v-else class="vertical-image-box">
                <el-upload
                    ref="upload-model"
                    :multiple="false"
                    :limit="1"
                    :on-exceed="handleUploadExceed"
                    :before-upload="(file) => beforeUploadToOss(file, 'model')"
                    :http-request="(entity) => uploadToOss(entity, 'model')"
                    :on-preview="handlePictureCardPreview"
                    list-type="picture-card"
                    accept="image/*">
                  <el-button type="primary" link :loading="uploadModelImageLoading">
                    <el-icon>
                      <CirclePlusFilled/>
                    </el-icon>
                    点击上传
                  </el-button>
                  <template #tip>
                    <div class="el-upload__tip">
                      图片大于5MB时会自动被压缩，为了保证效果，图片背景建议为纯色背景
                    </div>
                  </template>
                </el-upload>
              </div>
            </div>
            <!-- 右边区域 -->
            <div class="right">
              <!-- 上部分 -->
              <div class="top">
                <h4 class="mb-2">上衣/全身衣</h4>
                <div v-if="topGarmentImageUrl" class="image-container">
                  <el-image :src="topGarmentImageUrl"
                            fit="contain"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list="[topGarmentImageUrl]"
                            class="horizontal-image-box">
                  </el-image>
                  <!-- 删除图标 -->
                  <el-icon @click="removeImage('topGarment')"
                           class="delete-icon">
                    <Delete/>
                  </el-icon>
                </div>
                <div v-else class="horizontal-image-box">
                  <el-upload
                      ref="upload-model"
                      :multiple="false"
                      :limit="1"
                      :on-exceed="handleUploadExceed"
                      :before-upload="(file) => beforeUploadToOss(file, 'topGarment')"
                      :http-request="(entity) => uploadToOss(entity, 'topGarment')"
                      :on-preview="handlePictureCardPreview"
                      list-type="picture-card"
                      accept="image/*">
                    <el-button type="primary" link :loading="uploadModelImageLoading">
                      <el-icon>
                        <CirclePlusFilled/>
                      </el-icon>
                      点击上传
                    </el-button>
                    <template #tip>
                      <div class="el-upload__tip">
                        图片大于5MB时会自动被压缩，为了保证效果，图片背景建议为纯色背景
                      </div>
                    </template>
                  </el-upload>
                </div>
              </div>
              <!-- 下部分 -->
              <div class="bottom">
                <h4 class="mt-2 mb-2">下衣</h4>
                <div v-if="lowerGarmentImageUrl" class="image-container">
                  <el-image :src="lowerGarmentImageUrl"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list="[lowerGarmentImageUrl]"
                            fit="contain"
                            class="horizontal-image-box">
                  </el-image>
                  <!-- 删除图标 -->
                  <el-icon @click="removeImage('lowerGarment')"
                           class="delete-icon">
                    <Delete/>
                  </el-icon>
                </div>
                <div v-else class="horizontal-image-box">
                  <el-upload
                      ref="upload-model"
                      :multiple="false"
                      :limit="1"
                      :on-exceed="handleUploadExceed"
                      :before-upload="(file) => beforeUploadToOss(file, 'lowerGarment')"
                      :http-request="(entity) => uploadToOss(entity, 'lowerGarment')"
                      :on-preview="handlePictureCardPreview"
                      list-type="picture-card"
                      accept="image/*">
                    <el-button type="primary" link :loading="uploadModelImageLoading">
                      <el-icon>
                        <CirclePlusFilled/>
                      </el-icon>
                      点击上传
                    </el-button>
                    <template #tip>
                      <div class="el-upload__tip">
                        图片大于5MB时会自动被压缩，为了保证效果，图片背景建议为纯色背景
                      </div>
                    </template>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
          <!--功能操作区域-->
          <div class="opt-box">
            <div>
              <el-button type="primary" size="large" round @click="handleTryOn" :loading="tryOnLoading">
                <el-icon class="el-icon--left">
                  <Promotion/>
                </el-icon>
                一键换装
              </el-button>
              <span class="text-sm opacity-60 ml-3 golden-text">消耗15金币</span>
            </div>
            <div>
              <el-button type="success" size="large" round @click="handleTryOnEnhanced" :loading="tryOnLoading">
                <el-icon class="el-icon--left">
                  <Promotion/>
                </el-icon>
                一键换装+画质增强
              </el-button>
              <span class="text-sm opacity-60 ml-3 golden-text">消耗20金币</span>
            </div>
          </div>
          <!--换装结果区域-->
          <div class="title-icon-box mt-40">
            <try-on-result-icon :height="32" :width="32"></try-on-result-icon>
            <h2 class="font-bold">换装结果</h2>
          </div>
          <!-- 添加Tip提示 -->
          <div class="flex items-center justify-center">
            <el-alert
                type="success"
                title="一键换装预计60s，换装+画质增强预计120s，页面下方可以查看 示例图片 和 历史记录"
                show-icon
                class="tip-text"
                :closable="false"
                center
                :style="{ width: '600px',  fontSize: '16px', marginTop: '10px' }">
            </el-alert>
          </div>
          <div class="try-on-result-box">
            <div class="vertical-image-box">
              <div v-if="tryOnResultImageUrl" class="image-container">
                <el-image :src="tryOnResultImageUrl"
                          :max-scale="7"
                          :min-scale="0.2"
                          :preview-src-list="[tryOnResultImageUrl]"
                          fit="contain"
                          class="vertical-image-box">
                  <template #error>
                    <div class="image-slot">
                      <el-icon>
                        <icon-picture></icon-picture>
                      </el-icon>
                      图片无法加载
                    </div>
                  </template>
                </el-image>
                <!-- 下载图标 -->
                <el-icon @click="downloadImage(tryOnResultImageUrl)" class="download-icon">
                  <Download/>
                </el-icon>
              </div>
              <div v-else>
                <div v-if="tryOnLoading" class="try-on-timer">
                  运行中，已执行 {{ tryOnSeconds }} 秒
                </div>
              </div>
            </div>
          </div>
          <!--示例图区域-->
          <div class="title-icon-box font-bold mt-4">
            <ai-model-icon :height="24" :width="24"></ai-model-icon>
            <h3>模特示例图</h3>
          </div>
          <div class="image-gallery">
            <el-image
                v-for="(image, index) in modelDemoImages"
                :key="index"
                :src="image"
                fit="cover"
                class="demo-image"
                :class="{ selected: selectedModelImageIndex === index }"
                @click="handleClickDemoModelImage(index)"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-src-list="[image]"
            ></el-image>
          </div>
          <div class="title-icon-box font-bold mt-4">
            <top-garment-icon :height="24" :width="24"></top-garment-icon>
            <h3>上衣示例图</h3>
          </div>
          <div class="image-gallery">
            <el-image
                v-for="(image, index) in topGarmentImages"
                :key="index"
                :src="image"
                fit="cover"
                class="demo-image"
                :class="{ selected: selectedTopGarmentImageIndex === index }"
                @click="handleClickDemoTopGarmentImage(index)"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-src-list="[image]"
            ></el-image>
          </div>
          <div class="title-icon-box font-bold mt-4">
            <lower-garment-icon :height="24" :width="24"></lower-garment-icon>
            <h3>下衣示例图</h3>
          </div>
          <div class="image-gallery">
            <el-image
                v-for="(image, index) in lowerGarmentImages"
                :key="index"
                :src="image"
                fit="cover"
                class="demo-image"
                :class="{ selected: selectedLowerGarmentImageIndex === index }"
                @click="handleClickDemoLowerGarmentImage(index)"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-src-list="[image]"
            ></el-image>
          </div>
        </div>
      </div>

      <!--历史记录区域-->
      <div class="table-show-container">
        <h2>历史记录</h2>
        <div class="flex justify-end">
          <el-button plain type="primary" @click="clickRefreshTasks">刷新</el-button>
        </div>
        <el-table :data="tryOnTasks" style="width: 100%;">
          <el-table-column prop="task_name" label="任务名"/>
          <el-table-column prop="task_status" label="当前状态">
            <template #default="scope">
              <el-tag :type="getTaskStatusTag(scope.row.task_status)">{{
                  getTaskStatusText(scope.row.task_status)
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="提交时间"/>
          <el-table-column prop="model_image_url" width="200" label="模特图">
            <template #default="scope">
              <el-image v-if="scope.row.model_image_url"
                        :src="scope.row.model_image_url"
                        :key="scope.row.task_id"
                        fit="contain"
                        class="try-on-result-image"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        @click="handlePictureCardPreview({url:scope.row.model_image_url})"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="top_garment_url" width="200" label="上衣图">
            <template #default="scope">
              <el-image v-if="scope.row.top_garment_url"
                        :src="scope.row.top_garment_url"
                        :key="scope.row.task_id"
                        fit="contain"
                        class="try-on-result-image"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        @click="handlePictureCardPreview({url:scope.row.top_garment_url})"
              ></el-image>
              <div v-else style="text-align: center; color: #999999">
                无
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="lower_garment_url" width="200" label="下衣图">
            <template #default="scope">
              <el-image v-if="scope.row.lower_garment_url"
                        :src="scope.row.lower_garment_url"
                        :key="scope.row.task_id"
                        fit="contain"
                        class="try-on-result-image"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        @click="handlePictureCardPreview({url:scope.row.lower_garment_url})"
              ></el-image>
              <div v-else style="text-align: center; color: #999999">
                无
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="image_url" width="200" label="换装效果图">
            <template #default="scope">
              <el-image v-if="scope.row.image_url"
                        :src="scope.row.image_url"
                        :key="scope.row.task_id"
                        fit="contain"
                        class="try-on-result-image"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.4"
                        @click="handlePictureCardPreview({url:scope.row.origin_image_url})"
              ></el-image>
              <div v-else style="text-align: center; color: #999999">
                无
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="refiner_image_url" width="200" label="换装效果图—增强">
            <template #default="scope">
              <el-image v-if="scope.row.refiner_image_url"
                        :src="scope.row.refiner_image_url"
                        :key="scope.row.task_id"
                        fit="contain"
                        class="try-on-result-image"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        @click="handlePictureCardPreview({url:scope.row.refiner_image_url})"
              ></el-image>
              <div v-else style="text-align: center; color: #999999">
                无
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页数据-->
        <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                    v-model:limit="queryParams.pageSize" @pagination="getTryOnTasks"/>
      </div>

      <!--图片预览区域-->
      <div>
        <el-dialog v-model="dialogVisible" style="width: 620px; height: 860px">
          <el-image style="width: 600px; height: 800px" :src="dialogImageUrl" fit="contain">
          </el-image>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import FeatureIcon from '@/components/icons/FeatureIcon.vue';
import AiModelIcon from '@/components/icons/AiModelIcon.vue';
import TopGarmentIcon from '@/components/icons/TopGarmentIcon.vue';
import LowerGarmentIcon from "@/components/icons/LowerGarmentIcon.vue";
import PreviewIcon from "@/components/icons/PreviewIcon.vue";
import TryOnResultIcon from "@/components/icons/TryOnResultIcon.vue";
import {CirclePlusFilled} from "@element-plus/icons-vue";
import {CircleClose, Delete, Promotion, Picture as IconPicture, Download} from '@element-plus/icons-vue';
import axios from "axios";
import imageCompression from 'browser-image-compression';
import Pagination from "@/components/pagination/index.vue";


export default {
  name: "ai-try-on-demo",
  components: {
    Pagination,
    TryOnResultIcon,
    CirclePlusFilled,
    FeatureIcon,
    CircleClose,
    AiModelIcon,
    TopGarmentIcon,
    LowerGarmentIcon,
    PreviewIcon,
    Delete,
    Promotion,
    IconPicture,
    Download
  },

  data() {
    return {
      // 功能列表
      functionInfoList: [
        {
          icon: "feature",
          title: "一件换装",
          info: "AI驱动的一键换装功能，支持自动更换服装、背景处理，生成风格化视频，适用于个人和商业场景。",
          url: "",
          path: ""
        },
        {
          icon: "feature",
          title: "智能混剪",
          info: "通过AI算法进行视频素材的智能剪辑，自动识别关键场景与情绪，快速生成个性化视频内容。",
          url: "",
          path: ""
        },
        {
          icon: "feature",
          title: "营销视频",
          info: "AI辅助生成营销视频，支持场景切换、文字自动添加和语音合成，快速生成高质量广告视频。",
          url: "",
          path: ""
        }
      ],
      modelDemoImages: [],
      topGarmentImages: [],
      lowerGarmentImages: [],
      selectedModelImageIndex: -1,
      modelImageUrl: "",
      selectedTopGarmentImageIndex: -1,
      topGarmentImageUrl: "",
      selectedLowerGarmentImageIndex: -1,
      lowerGarmentImageUrl: "",
      ossUploadUrl: "",
      ossReadUrl: "",
      uploadModelImageLoading: false,
      uploadTopGarmentImageLoading: false,
      uploadLowerGarmentImageLoading: false,
      openEnhance: false,
      dialogVisible: false,
      dialogImageUrl: '',
      tryOnLoading: false,
      tryOnResultImageUrl: "",
      tryOnTimer: null, // 用于存储计时器ID
      tryOnSeconds: 0,
      tryOnTaskTotal: 0,
      tryOnTasks: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    }
  },
  mounted() {
    this.getDemoData();
    this.getTryOnTasks();
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    routePaths() {
      return this.$store.state.routePaths;
    },
  },
  methods: {
    startTryOnTimer() {
      this.tryOnSeconds = 0; // 初始化秒数
      this.tryOnTimer = setInterval(() => {
        this.tryOnSeconds += 1; // 每秒增加1秒
      }, 1000);
    },
    clearTryOnTimer() {
      this.tryOnSeconds = 0; // 初始化秒数
      if (this.tryOnTimer) {
        clearInterval(this.tryOnTimer); // 清除计时器
      }
    },
    clickRefreshTasks() {
      if (this.isLogin) {
        this.getTryOnTasks();
      } else {
        this.$message.warning("请先登录");
        this.total = 0;
        this.tryOnTasks = [];
      }
    },
    getTryOnTasks() {
      if (this.isLogin) {
        axiosInstance.post('/api/ai-try-on/query-task', {
          pageSize: this.queryParams.pageSize,
          page: this.queryParams.pageNum,
        }).then(response => {
          if (response.data && response.data.code === 0) {
            let data = response.data.data;
            this.total = data.total;
            this.tryOnTasks = data.results;
          }
        }).catch(error => {
          console.error(error)
        });
      } else {
        this.total = 0;
        this.tryOnTasks = [];
      }
    },
    getDemoData() {
      axiosInstance.get('/api/ai-try-on/demo-data').then(response => {
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.modelDemoImages = data.model_images;
          this.topGarmentImages = data.top_garment_images;
          this.lowerGarmentImages = data.lower_garment_images;
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
    },
    handleClickDemoModelImage(index) {
      this.selectedModelImageIndex = index;
      this.modelImageUrl = this.modelDemoImages[index];
    },
    handleClickDemoTopGarmentImage(index) {
      this.selectedTopGarmentImageIndex = index;
      this.topGarmentImageUrl = this.topGarmentImages[index];
    },
    handleClickDemoLowerGarmentImage(index) {
      this.selectedLowerGarmentImageIndex = index;
      this.lowerGarmentImageUrl = this.lowerGarmentImages[index];
    },
    removeImage(uploadType) {
      if (uploadType === 'model') {
        this.modelImageUrl = "";
      } else if (uploadType === 'topGarment') {
        this.topGarmentImageUrl = "";
      } else if (uploadType === 'lowerGarment') {
        this.lowerGarmentImageUrl = "";
      }
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    handleUploadExceed(files, fileList) {
      this.$message.warning('最多支持同时上传1个，请先删除已上传的文件');
    },
    async beforeUploadToOss(file, uploadType) {
      console.log("beforeUploadToOss: ", file)
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        this.$message.error('只能上传图片');
        return false
      }
      // if (file.size / 1024 / 1024 > 5) {
      //   this.$message.error('文件大于5MB，请重新上传');
      //   return false
      // }
      // 检查文件大小
      if (file.size / 1024 < 5) {
        this.$message.error('图片小于5KB，请重新上传');
        return false;
      }
      // 使用FileReader加载图片以获取其尺寸
      const image = await this.loadImage(file);
      const {width, height} = image;

      // 检查分辨率和尺寸
      const maxLength = Math.max(width, height);
      const minLength = Math.min(width, height);
      if (width < 150 || height < 150 || maxLength > 4096 || minLength < 150) {
        this.$message.error('图片分辨率不满足要求，请重新上传');
        return false;
      }
      try {
        const response = await axiosInstance.post(`/api/oss/url/sign_put_and_get`, {
          filename: file.name,
          method: 'put',
          platformType: 'ai-try-on',
        })
        console.log(response)
        if (response.data.code === 0) {
          this.ossUploadUrl = response.data.data.oss_url;
          this.ossReadUrl = response.data.data.read_oss_url;
        } else {
          this.$message.error('【' + file.name + '】无法上传，请稍后重试');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('【' + file.name + '】无法上传，请稍后重试');
        return false;
      }
      return true
    },
    // 图片上传到 OSS
    async uploadToOss(entity, uploadType) {
      let file = entity.file;
      console.log("uploadToOss entity", entity);
      console.log("uploadToOss uploadType", uploadType);

      this.$message.info("【" + file.name + "】" + "正在上传，请稍候");
      console.log("压缩前: ", file.size / 1024 / 1024, "MB")
      // 如果文件大于 5MB，执行压缩
      if (file.size / 1024 / 1024 > 5) {
        try {
          const options = {
            maxSizeMB: 5,  // 压缩后的最大文件大小
            maxWidthOrHeight: 4096,  // 压缩时的最大宽高
            useWebWorker: true,  // 使用 Web Worker 进行压缩
            initialQuality: 0.75,  // 图片质量
          };
          console.log("正在压缩文件...");
          file = await imageCompression(file, options);
          console.log("压缩成功，压缩后大小 ", file.size / 1024 / 1024, "MB");
        } catch (error) {
          console.error("图片压缩失败: ", error);
          this.$message.error("图片压缩失败，请重试");
          return;
        }
      }

      // 使用 axios 进行上传
      axios.put(this.ossUploadUrl, file, {
        headers: {
          'Content-Type': 'image/*',
        }
      }).then(response => {
        console.info("【" + file.name + "】" + "上传成功", response);
        this.$message.success("【" + file.name + "】" + "上传成功");

        // 根据不同的 uploadType 设置不同的 URL
        if (uploadType === 'model') {
          this.modelImageUrl = this.ossReadUrl;
        } else if (uploadType === 'topGarment') {
          this.topGarmentImageUrl = this.ossReadUrl;
        } else if (uploadType === 'lowerGarment') {
          this.lowerGarmentImageUrl = this.ossReadUrl;
        }
      }).catch(error => {
        this.$message.error("【" + file.name + "】" + "上传失败，请重试");
        console.error(error);
      });
    },


    // 加载图片并获取其宽高
    loadImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const img = new Image();
          img.onload = function () {
            resolve(img);
          };
          img.onerror = reject;
          img.src = e.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    handleTryOnEnhanced() {
      this.openEnhance = true;
      this.handleTryOn();
    },

    handleTryOn() {
      if (!this.isLogin) {
        this.$message.warning('请先登录');
        return
      }
      if (!this.modelImageUrl) {
        this.$message.warning('请上传模特图片');
        return
      }
      if (!this.topGarmentImageUrl && !this.lowerGarmentImageUrl) {
        this.$message.warning('请上传服装图片');
        return
      }
      this.tryOnResultImageUrl = "";
      this.startTryOnTimer();
      this.tryOnLoading = true;
      axiosInstance.post('/api/ai-try-on/start', {
        openEnhance: this.openEnhance,
        modelImageUrl: this.modelImageUrl,
        topGarmentImageUrl: this.topGarmentImageUrl,
        lowerGarmentImageUrl: this.lowerGarmentImageUrl,
      }).then(response => {
        this.tryOnLoading = false;
        if (response.data && response.data.code === 0) {
          let data = response.data.data;
          this.tryOnResultImageUrl = data.image_url;
          this.$store.dispatch('resetCoin', data.coin);
          this.getTryOnTasks();
        } else {
          this.$message.error("换装失败，请稍后重试");
        }
        this.clearTryOnTimer();
      }).catch(error => {
        this.tryOnLoading = false;
        this.clearTryOnTimer();
        console.error(error)
      });
    },
    downloadImage(url) {
      // 创建一个 a 标签
      const link = document.createElement('a');
      link.href = url; // 指定下载的图片 URL
      link.download = ''; // 可选：可以设置默认下载的文件名，例如 'image.jpg'
      document.body.appendChild(link); // 将链接添加到文档中
      link.click(); // 模拟点击
      document.body.removeChild(link); // 点击后移除链接
    },
    getTaskStatusText(status) {
      switch (status) {
        case 'pending':
          return '排队中';
        case 'doing':
          return '换装中';
        case 'done_success':
          return '换装完成';
        case 'done_fail':
          return '换装失败';
        default:
          return '换装失败';
      }
    },
    getTaskStatusTag(status) {
      switch (status) {
        case 'pending':
          return 'info';
        case 'doing':
          return 'primary';
        case 'done_success':
          return 'success';
        case 'done_fail':
          return 'error';
        default:
          return 'warning';
      }
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .el-input--mini .el-input__inner {
  height: 24px;
  line-height: 24px;
}

.image-gallery {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.demo-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid transparent; /* 默认透明的边框 */
  border-radius: 10px;
  width: 180px;
  height: 180px;
  margin: 10px;
}

.demo-image.selected {
  border-color: #409EFF; /* 选中样式 */
}

.ai-try-on-banner {
  box-sizing: border-box;
  position: relative;
  height: 450px;
  background: url("https://ai.bdstatic.com/file/A319A3C404CD46C09DA38D9D42FD627C") no-repeat 50% 50%;
  background-size: cover;
  overflow: hidden;
}

.ai-try-on-banner-content {
  overflow: hidden;
  text-align: left;
  width: 730px;
  padding-right: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ai-try-on-banner-title {
  height: 67px;
  font-size: 48px;
  letter-spacing: 2px;
  color: #fff;
  position: relative;
}

.ai-try-on-banner-info {
  margin: 20px 110px 0 0;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  /*color: #faad15;*/
}

.body-container {
  background-color: #ffff;
  width: 100%;
  margin-bottom: 40px;
}

.function-list-bg {
  background-image: url("../../assets/images/production-list-bg.jpg");
  background-size: 100%;
  background-color: #edeeef;
  /*height: 540px;*/
  padding: 30px 0 60px 0;
  border-radius: 10px;
}

.function-container {
  width: 1300px;
  margin: 0 auto;
}

.function-container > h2 {
  font-size: 28px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}

.function-list-body {
  max-width: 100%;
  display: flex;
  margin: 20px auto 0;
  justify-content: space-between;
}

.confidence-low {
  border: 1px solid red;
}

.function-item-container {
  display: flex;
  justify-content: left;
}

.function-item-content {
  text-align: left;
  margin-right: 10px;
}

.function-item-content > h3 {
  display: inline-block;
  height: 64px;
  line-height: 64px;
  margin: 0;
}

.function-item-content > p {
  margin: 0;
  height: 42px;
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
}

.table-show-container {
  width: 90%;
  margin: 0 auto;
  min-height: 800px;
  padding: 10px 10px;
  text-align: center;
}

.demo-show-container > h2 {
  font-size: 28px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}

.show-container {
  width: 100%;
  min-height: 600px;
  text-align: center;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  margin-top: 20px;
}

.show-container > h3 {
  height: 64px;
  line-height: 64px;
  text-align: center;
  margin: 0;
}

.upload-file {
  text-align: center;
  align-items: center;
  display: flex;
}

/deep/ .el-upload-dragger {
  height: 500px;
  width: 580px;
  overflow-y: auto;
  overflow-x: auto;
}

.icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.show-result {
  height: 500px;
  overflow-y: auto;
}

/deep/ .el-input__inner:focus {
  border-color: #409eff;
  outline: 0;
  position: relative;
  z-index: 10;
}

.title-icon-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.preview-container {
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  align-items: baseline;
  gap: 20px; /* 左右部分的间距 */
  //height: 800px;
  box-sizing: border-box;
  padding: 10px;
}

.left {
  //flex: 1;
  width: 600px; /* 固定宽度 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right {
  //flex: 1;
  width: 600px; /* 固定宽度 */
  display: flex;
  flex-direction: column; /* 右侧上下两部分 */
  height: 100%;
}

.top,
.bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vertical-image-box {
  width: 600px;
  height: 840px;
  border-radius: 10px; /* 边角圆滑 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* 添加阴影让图片更美观 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-image-box {
  width: 600px;
  height: 400px;
  border-radius: 10px; /* 边角圆滑 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* 添加阴影让图片更美观 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  position: relative; /* 使删除图标可以绝对定位 */
}

.delete-icon {
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离上边距 */
  right: 10px; /* 距离右边距 */
  cursor: pointer; /* 鼠标悬浮时显示为手型 */
  color: red; /* 图标颜色 */
  z-index: 1; /* 确保图标位于图片之上 */
}

.download-icon {
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离上边距 */
  right: 10px; /* 距离右边距 */
  cursor: pointer; /* 鼠标悬浮时显示为手型 */
  color: gray; /* 图标颜色 */
  z-index: 1; /* 确保图标位于图片之上 */
}

.opt-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 20px;
}

.try-on-result-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
}

.try-on-timer {
  font-size: 20px; /* 字体大小 */
  color: #a0cfff; /* 字体颜色 */
  text-align: center; /* 文字居中 */
}

.try-on-result-image {
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid transparent; /* 默认透明的边框 */
  border-radius: 20px;
  width: 180px;
  height: 240px;
  margin: 10px;
  z-index: 1;
}

.table-show-container > h2 {
  font-size: 20px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 40px;
}

.golden-text {
  color: rgb(189, 137, 6);
  font-weight: bold;
}

::v-deep .el-table th {
  text-align: center; /* 设置所有表头单元格的文本居中 */
}
</style>