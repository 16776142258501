<template>
  <div class="p-6 bg-gray-100 min-h-screen">
    <div class="bg-white p-6 rounded-lg shadow-md">
      <!-- 标题与设置按钮 -->
      <div class="flex justify-between items-center mb-4">
        <div>
          <span class="text-lg font-semibold">场景管理</span>
          <el-button class="ml-5 text-sm" link size="small" type="primary" @click="handleClickName">
            {{ sceneConfigBaseInfo.configName }}
          </el-button>
        </div>
        <div>
          <el-button type="info" plain @click="handleClickEditSceneConfig">编辑配置</el-button>
          <el-button type="success" plain @click="handleClickSaveSceneConfig" :loading="saveSceneConfigLoading">
            保存配置
          </el-button>
          <el-button type="primary" plain @click="handleClickProduceVideo">生产视频</el-button>
        </div>
      </div>

      <!-- 提示文字 -->
      <div class="text-sm text-gray-500 mb-4">
        建议上传16:9，分辨率为（1080x1920）及以上的竖版视频素材；且每个场景视频素材尺寸统一
      </div>
      <!-- 场景列表 -->
      <div class="text-sm font-semibold mb-4">场景列表</div>
      <!-- 场景按钮列表 -->
      <div class="flex space-x-4 mb-4">
        <div v-for="(scene, index) in sceneConfigParam.scenes" :key="index">
          <el-button type="warning" size="small" circle plain @click="handleDeleteScene(index)">
            <el-icon>
              <Minus/>
            </el-icon>
          </el-button>
          <el-button type="default" round :class="selectedSceneIndex === index ? 'selected-btn' : '' "
                     @click="handleClickScene(index)">::
            场景{{ index + 1 }}
          </el-button>
          <el-button type="primary" size="small" circle plain @click="handleAddScene(index)">
            <el-icon>
              <Plus/>
            </el-icon>
          </el-button>
        </div>
      </div>

      <!-- 素材上传和显示 -->
      <div class="mb-4">
        <span class="text-sm font-semibold">场景视频素材</span>
        <span
            class="text-gray-500 text-sm ml-5 mt-4">素材：{{
            sceneConfigParam.scenes[selectedSceneIndex].material_info_list.length
          }} 个</span>
      </div>
      <!-- 素材信息 -->
      <div class="flex flex-wrap gap-4 mb-4">
        <!-- 添加素材 -->
        <el-button class="image-box" style="color: #24a5ff" @click="handleClickAdd">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          点击添加
        </el-button>
        <!-- 素材缩略图显示 -->
        <div class="video-container"
             v-for="(url, index) in sceneConfigParam.scenes[selectedSceneIndex].material_info_list" :key="index">
          <video class="video-box" :key="index" :src="url" controls style="cursor: pointer;"></video>
          <el-icon
              @click="removeVideo(index)"
              class="delete-icon">
            <Delete/>
          </el-icon>
        </div>
      </div>
      <!-- 场景文案 -->
      <div class="mb-4">
        <span class="text-sm font-semibold">场景文案</span>
        <div class="text-sm text-gray-500 mb-4 mt-2">
          根据每个场景设置的时长计算口播文案字数，1s 时长最多5个字，单个场景建议输入10~25个字
        </div>
        <div v-for="(text, index) in sceneConfigParam.scenes[selectedSceneIndex].subtitle_text" :key="index">
          <div class="mt-2">
            <el-button type="warning" size="small" class="mr-2" circle plain @click="handleDeleteSceneSubtitle(index)">
              <el-icon>
                <Minus/>
              </el-icon>
            </el-button>
            <el-input
                v-model="sceneConfigParam.scenes[selectedSceneIndex].subtitle_text[index]"
                style="width: 700px"
                maxlength="25"
                placeholder="如果需要不同场景使用不同字幕，请输入该场景字幕，如有有多个字幕会随机使用一个"
                show-word-limit
                clearable
                @input="sceneConfigParam.scenes[selectedSceneIndex].subtitle_text[index] = sceneConfigParam.scenes[selectedSceneIndex].subtitle_text[index].trim()"
                type="text"
            />
            <el-button type="primary" size="small" class="ml-2" circle plain @click="handleAddSceneSubtitle(index)">
              <el-icon>
                <Plus/>
              </el-icon>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加视频素材 对话框 -->
    <el-dialog v-if="showAddMaterialDialog"
               title="添加视频素材"
               width="60%"
               v-model="showAddMaterialDialog"
               :close-on-click-modal="false"
               destroy-on-close>
      <el-tabs v-model="activeAddMateriaName" class="demo-tabs">
        <el-tab-pane label="素材库" name="tag">
          <el-form :model="materialForm" label-width="auto">
            <el-form-item label="素材标签：" prop="tag">
              <el-select v-model="materialForm.tag" style="width: 200px" placeholder="请选择素材标签" clearable
                         @change="handleTagChange">
                <el-option
                    v-for="tag in allTags"
                    :key="tag.code"
                    :label="tag.name"
                    :value="tag.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="视频个数：">
              <span>{{ videoInfoByTagList ? videoInfoByTagList.length : 0 }}</span>
            </el-form-item>
            <el-form-item label="视频详情：">
              <el-checkbox v-model="materialForm.selectAll" @change="handleChangeSelectAll">全选</el-checkbox>
            </el-form-item>
          </el-form>
          <div class="flex flex-wrap gap-4 mb-4 video-list-container">
            <div class="video-container" v-for="(item, index) in videoInfoByTagList" :key="index">
              <el-checkbox class="checkbox-icon" :key="index" v-model="item.checked"></el-checkbox>
              <video class="video-box" :key="index" :src="item.url" controls style="cursor: pointer;"></video>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="上传" name="upload">
          <el-upload
              v-model:file-list="fileList"
              ref="upload"
              :multiple=true
              :limit="50"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              list-type="picture-card"
              accept="video/*"
              :http-request="uploadToOss">
            <el-button type="primary" link>
              <el-icon>
                <CirclePlusFilled/>
              </el-icon>
              点击上传
            </el-button>
            <template #file="{file}">
              <div class="flex justify-center">
                <video class="el-upload-list__item-thumbnail" :src="file.url"></video>
                <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handleUploadPreview(file)">
                           <el-icon><ZoomIn/></el-icon>
                        </span>
                        <span class="el-upload-list__item-delete" @click="handleRemoveFile(file)">
                           <el-icon><Delete/></el-icon>
                        </span>
                </span>
              </div>
            </template>
            <template #tip>
              <div class="el-upload__tip">
                请上传小于50MB的视频，最多支持同时上传50个.
              </div>
            </template>
          </el-upload>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showAddMaterialDialog = false">取消</el-button>
          <el-button type="primary" @click="handleAddNewVideo">添加</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 视频预览对话框 -->
    <el-dialog v-if="previewVideoDialogVisible" v-model="previewVideoDialogVisible" title="预览视频" destroy-on-close>
      <div class="flex justify-center">
        <video v-if="filePathOrUrl" :src="filePathOrUrl" controls style="width: 600px; height: 800px"></video>
      </div>
    </el-dialog>
  </div>

  <!-- 配置编辑对话框 -->
  <el-dialog v-if="showEditDialog"
             title="编辑场景配置"
             v-model="showEditDialog"
             width="60%"
             destroy-on-close
             :close-on-click-modal="false">
    <el-divider>
      <el-icon>
        <star-filled/>
      </el-icon>
      基本信息
      <el-icon>
        <star-filled/>
      </el-icon>
    </el-divider>
    <el-form label-position="left" label-width="auto" style="max-width: 600px">
      <el-form-item label="场景配置名：" prop="name">
        <el-input v-model="sceneConfigBaseInfo.configName" placeholder="请输入场景配置名"></el-input>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input placeholder="可选，备注" v-model="sceneConfigBaseInfo.remark"/>
      </el-form-item>
    </el-form>
    <el-divider>
      <el-icon>
        <star-filled/>
      </el-icon>
      全局参数
      <el-icon>
        <star-filled/>
      </el-icon>
    </el-divider>
    <el-form label-position="left" label-width="auto" style="max-width: 600px">
      <el-form-item label="输出视频分辨率：">
        <el-input disabled v-model="sceneConfigParam.video_resolution" placeholder="输出视频分辨率"></el-input>
      </el-form-item>
      <el-form-item label="FPS：">
        <el-input disabled placeholder="fps" v-model="sceneConfigParam.fps"/>
      </el-form-item>
      <el-form-item label="保留原音频：">
        <el-switch
            v-model="sceneConfigParam.keep_audio"
            :active-value="1"
            :inactive-value="0"/>
      </el-form-item>
      <el-form-item label="字幕模式：">
        <el-select v-model="sceneConfigParam.subtitle_mode" placeholder="全局字幕模式">
          <el-option label="整体字幕" value="overall"></el-option>
          <el-option disabled label="片段字幕" value="clip"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="整体字幕内容：">
        <div class="mb-4">
          <div class="text-sm text-gray-500 mb-4 mt-2">
            视频成片将使用整体字幕，请根据每个场景设置的时长计算单个场景文案字数，1s时长最多5个字，单个场景建议输入10~25个字，
            整体字幕个数=单个场景字幕个数*场景数
          </div>
          <div v-for="(text, index) in sceneConfigParam.subtitle_text" :key="index">
            <div class="mt-2 flex justify-center items-center">
              <el-button type="warning" size="small" class="mr-2" circle plain
                         @click="handleDeleteOverallSubtitle(index)">
                <el-icon>
                  <Minus/>
                </el-icon>
              </el-button>
              <el-input
                  v-model="sceneConfigParam.subtitle_text[index]"
                  style="width: 600px"
                  maxlength="300"
                  placeholder="请输入整体字幕，成片将使用该整体字幕"
                  show-word-limit
                  clearable
                  @input="sceneConfigParam.subtitle_text[index] = sceneConfigParam.subtitle_text[index].trim()"
                  type="textarea"
                  :rows="3"
              />
              <el-button type="primary" size="small" class="ml-2" circle plain @click="handleAddOverallSubtitle(index)">
                <el-icon>
                  <Plus/>
                </el-icon>
              </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showEditDialog = false">取消</el-button>
        <el-button type="success" @click="handleClickSaveSceneConfig" :loading="saveSceneConfigLoading">保存</el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 运行视频生产对话框 -->
  <el-dialog v-if="showRunVideoMixDialog"
             title="生产视频"
             v-model="showRunVideoMixDialog"
             width="50%"
             destroy-on-close
             :close-on-click-modal="false">
    <el-form label-position="left" label-width="auto" style="max-width: 600px">
      <el-form-item label="预期生产视频数量：">
        <el-input-number placeholder="请输入预期生成视频数量" v-model="sceneConfigParam.video_num" :min="1" :max="200"/>
      </el-form-item>
      <el-form-item label="视频重复率：">
        <el-input-number v-model="sceneConfigParam.max_similarity" :precision="2" :step="0.05" :min="0" :max="1"/>
      </el-form-item>
      <el-form-item label="成品视频素材标签：" required>
        <el-input v-model="sceneConfigParam.output_tag_name"
                  placeholder="请输入素材标签，方便后续查看，例如：20241025乐璟宣传片"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showRunVideoMixDialog = false">取消</el-button>
        <el-button type="success" @click="handleRunVideoMix" :loading="runVideoMixLoading">执行</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {
  Edit,
  CirclePlus,
  Delete,
  Minus,
  Plus,
  Remove,
  Picture,
  CirclePlusFilled,
  ZoomIn,
  StarFilled
} from '@element-plus/icons-vue'
import VideoPlayer from "@/components/VideoPlayer.vue";
import axios from "axios";

export default {
  components: {
    CirclePlusFilled,
    VideoPlayer,
    Delete,
    Edit,
    CirclePlus,
    Minus,
    Plus,
    Remove,
    Picture,
    ZoomIn,
    StarFilled,
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  data() {
    return {
      runVideoMixLoading: false,
      showRunVideoMixDialog: false,
      saveSceneConfigLoading: false,
      showEditDialog: false,
      sceneConfigBaseInfo: {
        configId: null,
        configName: "",
        remark: "",
      },
      defaultSceneItem: {
        "scene_name": "",
        "subtitle_text": [""],
        "material_use_mode": "random",
        "material_info_list": [],
      },
      sceneConfigParam: {
        "subtitle_mode": "overall", // overall 或 clip
        "subtitle_text": [""], // 整体字幕内容
        "subtitle_use_mode": "random", // 字幕使用模式
        "video_resolution": "1080x1920", // 结果视频分辨率
        "fps": 30,
        "keep_audio": 0, // 是否保留原视频的音频
        "output_tag_name": "", // 输出成品对应的标签名称
        "video_num": 10, // 预计生产视频数量
        "max_similarity": 0.5, // 视频片段重复度
        // 场景列表
        "scenes": [
          {
            "scene_name": "",
            "subtitle_text": [""],
            "material_use_mode": "random",
            "material_info_list": [],
          },
        ]
      },
      selectedSceneIndex: 0,
      showAddMaterialDialog: false,
      activeAddMateriaName: "tag", // tag or upload
      materialForm: {
        tag: "",
        selectAll: false,
        videoCount: 0,
        pageSize: 20,
        pageNum: 1,
      },
      videoInfoByTagList: [],
      allTags: [],
      dialogVisible: false,
      dialogImageUrl: '',
      previewVideoDialogVisible: false,
      filePathOrUrl: "",
      // 文件上传
      fileList: [],
      fileUploadOssInfoList: [],

    };
  },
  created() {
    if (this.$route.query.configId) {
      this.sceneConfigBaseInfo.configId = this.$route.query.configId
    }
    if (!this.sceneConfigBaseInfo.configId) {
      this.$message.warning('请先选择场景配置');
      this.$router.push({path: `/video-mix/scene-list`});
    }
  },
  mounted() {
    if (this.sceneConfigBaseInfo.configId) {
      this.querySceneConfigDetail();
    }
    this.fetchTagInfos();
  },
  methods: {
    handleClickEditSceneConfig() {
      this.showEditDialog = true;
    },
    handleRunVideoMix() {
      if (!this.sceneConfigParam.output_tag_name) {
        this.$message.warning('请先填写输出成品标签名称');
        return;
      }
      this.runVideoMixLoading = true;
      // 深度复制this.sceneConfigParam
      let sceneConfigParamCopy = JSON.parse(JSON.stringify(this.sceneConfigParam));
      sceneConfigParamCopy.config_id = this.sceneConfigBaseInfo.configId;
      sceneConfigParamCopy.config_name = this.sceneConfigBaseInfo.configName;
      axiosInstance.post('/api/video-mix/task/add', sceneConfigParamCopy).then(response => {
        this.runVideoMixLoading = false;
        if (response.data.code === 0) {
          this.showRunVideoMixDialog = false;
          this.$message.success('任务执行中，请到【混剪任务】页面查询详情');
          // todo 跳转到任务列表页面，并且携带参数
          let taskId = response.data.data.taskId;
          this.$router.push({path: `/video-mix/task-list`, query: {taskId: taskId}});
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.runVideoMixLoading = false;
        console.error('error', error);
      });
    },

    handleClickSaveSceneConfig() {
      this.saveSceneConfigLoading = true;
      axiosInstance.post('/api/video-mix/config/update', {
        "configId": this.sceneConfigBaseInfo.configId,
        "configName": this.sceneConfigBaseInfo.configName,
        "remark": this.sceneConfigBaseInfo.remark,
        "configParam": JSON.stringify(this.sceneConfigParam),
      }).then(response => {
        this.saveSceneConfigLoading = false;
        console.log('response', response);
        if (response.data.code === 0) {
          this.showEditDialog = false;
          this.$message.success('保存成功');
          this.querySceneConfigDetail();
        } else {
          this.$message.error('保存成功，请重试');
        }
      }).catch(error => {
        this.saveSceneConfigLoading = false;
        console.error('error', error);
      });
    },
    handleClickProduceVideo() {
      this.showRunVideoMixDialog = true;
    },
    handleDeleteScene(index) {
      if (this.sceneConfigParam.scenes.length > 1) {
        this.sceneConfigParam.scenes.splice(index, 1);
      } else {
        this.$message.warning('至少保留一个场景');
      }
    },
    handleAddScene(index) {
      // this.defaultSceneItem 深度copy
      let copyOne = JSON.parse(JSON.stringify(this.defaultSceneItem));
      this.sceneConfigParam.scenes.splice(index + 1, 0, copyOne)
    },
    handleDeleteSceneSubtitle(index) {
      if (this.sceneConfigParam.scenes.length > 1) {
        this.sceneConfigParam.scenes[this.selectedSceneIndex].subtitle_text.splice(index, 1);
      } else {
        this.$message.warning('无法删除');
      }
    },
    handleAddSceneSubtitle(index) {
      this.sceneConfigParam.scenes[this.selectedSceneIndex].subtitle_text.splice(index + 1, 0, "")
    },
    handleDeleteOverallSubtitle(index) {
      if (this.sceneConfigParam.subtitle_text.length > 1) {
        this.sceneConfigParam.scenes[this.selectedSceneIndex].subtitle_text.splice(index, 1);
      } else {
        this.$message.warning('无法删除');
      }
    },
    handleAddOverallSubtitle(index) {
      this.sceneConfigParam.subtitle_text.splice(index + 1, 0, "")
    },

    handleChangeSelectAll(newValue) {
      if (this.videoInfoByTagList) {
        this.videoInfoByTagList.forEach(info => {
          info.checked = newValue;
        })
      }
      console.log(this.videoInfoByTagList)
    },
    // 查询tag
    fetchTagInfos() {
      if (!this.isSuperAdmin) {
        this.tagQueryParams.tenantId = this.tenantId;
      }
      axiosInstance.post('/api/tag/list', {
        "pageNum": 1,
        "pageSize": 1000,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.allTags = response.data.data.results;
          this.allTags.forEach(tag => {
            tag.checked = false;
          })
          // console.log("this.allTags", this.allTags)
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
    },
    removeVideo(index) {
      this.sceneConfigParam.scenes[this.selectedSceneIndex].material_info_list.splice(index, 1);
    },
    handleClickName() {
      this.$router.push({path: `/video-mix/scene-list`});
    },
    handleClickScene(index) {
      this.selectedScene = this.sceneConfigParam.scenes[index];
      this.selectedSceneIndex = index;
    },
    querySceneConfigDetail() {
      console.log('查询场景配置详情');
      let that = this;
      if (this.sceneConfigBaseInfo.configId) {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post('/api/video-mix/config/get', {
          "configId": this.sceneConfigBaseInfo.configId
        }).then(response => {
          console.log('response', response);
          if (response.data.code === 0) {
            this.sceneConfigBaseInfo.configName = response.data.data.config_name;
            this.sceneConfigBaseInfo.remark = response.data.data.remark;
            if (response.data.data.config_param) {
              this.sceneConfigParam = JSON.parse(response.data.data.config_param);
            }
            console.log(this.sceneConfigParam)
          } else {
            this.$message.warning('查询失败，请先选择场景配置');
            this.$router.push({path: `/video-mix/scene-list`});
          }
        }).catch(error => {
          that.$message({
            type: 'error',
            message: '删除失败: ' + error.message
          });
        });

      } else {
        this.$message.error('请先选择场景配置');
      }
    },
    clearAddInfo() {
      // 清空历史的添加信息
      this.videoInfoByTagList = []
      // 清空历史的上传信息
      this.fileList = []
      this.fileUploadOssInfoList = []
    },
    handleClickAdd() {
      this.showAddMaterialDialog = true;
      this.clearAddInfo();
    },

    handleTagChange(newTag) {
      console.log('tag change', newTag);
      if (newTag) {
        axiosInstance.post('/api/material/list', {
          "pageNum": 1,
          "pageSize": 1000,
          "tag": newTag,
        }).then(response => {
          if (response.data && response.data.code === 0) {
            this.videoInfoByTagList = [];
            let results = response.data.data.results;
            // console.log('results', results)
            results.forEach(info => {
              if (info.type === 'video') {
                this.videoInfoByTagList.push({
                  checked: false,
                  url: info.oss_url,
                })
              }
            })
            console.log("this.videoInfoByTagList", this.videoInfoByTagList)
          } else {
            this.$message.error(response.data.msg);
          }
        }).catch(error => {
          console.error(error)
        });
      } else {
        this.videoInfoByTagList = [];
      }
    },

    handleAddNewVideo() {
      if (this.activeAddMateriaName === 'tag') {
        if (this.videoInfoByTagList && this.videoInfoByTagList.length > 0) {
          let selectList = this.videoInfoByTagList.filter(item => item.checked) || [];
          if (selectList.length === 0) {
            this.$message.warning('当前无视频可添加');
          } else {
            selectList.forEach(item => {
              this.sceneConfigParam.scenes[this.selectedSceneIndex].material_info_list.push(item.url)
            })
            this.showAddMaterialDialog = false;
          }
        }
      } else if (this.activeAddMateriaName === 'upload') {
        if (this.fileUploadOssInfoList && this.fileUploadOssInfoList.length > 0) {
          // 判断fileUploadOssInfoList是否有未上传完成的文件
          let notUploadSuccessFileList = this.fileUploadOssInfoList.filter(item => !item.uploadFinish);
          if (notUploadSuccessFileList.length > 0) {
            let message = '当前有' + notUploadSuccessFileList.length + '个文件未上传完成，是否立刻添加已上传完成的视频？';
            this.$confirm(message, '想一想～～', {
              confirmButtonText: '立刻添加',
              cancelButtonText: '再等等',
              type: 'warning',
            }).then(() => {
              console.log("等不及啦")
              let selectList = this.fileUploadOssInfoList.filter(item => item.uploadFinish) || [];
              if (selectList.length === 0) {
                this.$message.warning('当前无视频可添加');
                return
              }
              selectList.forEach(item => {
                this.sceneConfigParam.scenes[this.selectedSceneIndex].material_info_list.push(item.readOssUrl)
              })
              this.showAddMaterialDialog = false;
            }).catch(() => {
              console.log("再等等")
            })
          } else {
            let selectList = this.fileUploadOssInfoList.filter(item => item.uploadFinish) || [];
            if (selectList.length === 0) {
              this.$message.warning('当前无视频可添加');
              return
            }
            selectList.forEach(item => {
              this.sceneConfigParam.scenes[this.selectedSceneIndex].material_info_list.push(item.readOssUrl)
            })
            this.showAddMaterialDialog = false;
          }
        }
      }
    },
    handleRemoveFile(file) {
      console.log("handleRemoveFile", file)
      let selectIndex = -1;
      // 从 fileUploadOssInfoList 中删除
      for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
        if (this.fileUploadOssInfoList[i].uid === file.uid) {
          selectIndex = i;
        }
      }
      if (selectIndex !== -1) {
        this.fileUploadOssInfoList.splice(selectIndex, 1)
      }

      // 从 fileList 中删除
      selectIndex = -1;
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].uid === file.uid) {
          selectIndex = i;
        }
      }
      if (selectIndex !== -1) {
        this.fileList.splice(selectIndex, 1)
      }

      console.log("this.fileUploadOssInfoList", this.fileUploadOssInfoList)
      console.log("this.fileList", this.fileList)

    },
    handleExceed(files, fileList) {
      this.$message.warning('最多支持同时上传50个');
    },
    async beforeUpload(file) {
      console.log("beforeUpload: ", file)
      const isImage = file.type.startsWith('video/');
      if (!isImage) {
        this.$message.error('只能上传视频文件');
        return false
      }
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('只能上传视频文件');
        return false
      }
      let ossUploadUrl, ossReadUrl;
      try {
        const response = await axiosInstance.post(`/api/oss/url/sign_put_and_get`, {
          filename: file.name,
          method: 'put',
          platformType: 'video-mix',
        })
        console.log(response)
        if (response.data.code === 0) {
          ossUploadUrl = response.data.data.oss_url;
          ossReadUrl = response.data.data.read_oss_url;
        } else {
          this.$message.error('【' + file.name + '】无法上传，获取上传URL失败');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('【' + file.name + '】无法上传，获取上传URL失败');
        return false;
      }
      let uploadInfo = {
        name: file.name,
        uid: file.uid,
        ossUrl: ossUploadUrl,
        readOssUrl: ossReadUrl,
        uploadFinish: false,
      }
      // 遍历fileUploadOssInfoList
      for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
        if (this.fileUploadOssInfoList[i].uid === file.uid) {
          this.fileUploadOssInfoList[i] = uploadInfo
          return true
        }
      }
      // 新增
      this.fileUploadOssInfoList.push(uploadInfo)
      return true
    },
    uploadToOss(entity) {
      // console.log("uploadToOss entity", entity)
      // console.log("uploadToOss this.fileList", this.fileList)
      let file = entity.file
      let selectIndex = -1;
      for (let i = 0; i < this.fileUploadOssInfoList.length; i++) {
        if (this.fileUploadOssInfoList[i].uid === file.uid) {
          selectIndex = i;
        }
      }
      if (selectIndex === -1) {
        this.$message.error('【' + file.name + '】无法上传，获取上传URL失败');
        return
      }
      let ossUrl = this.fileUploadOssInfoList[selectIndex].ossUrl
      this.$message.info("【" + file.name + "】" + "正在上传，请稍后");
      axios.put(ossUrl, file, {
        headers: {
          'Content-Type': 'video/*',
        }
      }).then(response => {
        console.info("【" + file.name + "】" + "上传成功", response)
        this.$message.success("【" + file.name + "】" + "上传成功");
        this.fileUploadOssInfoList[selectIndex].uploadFinish = true
      }).catch(error => {
        // 移除
        this.fileUploadOssInfoList.splice(selectIndex, 1)
        this.$message.error("【" + file.name + "】" + "上传失败");
        console.error(error);
      });
    },
    handleUploadPreview(file) {
      console.log("handlePictureCardPreview file", file)
      this.filePathOrUrl = file.url;
      this.previewVideoDialogVisible = true;
    },
  }
};
</script>

<style scoped>

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  justify-content: center !important;
}

.p-6 {
  padding: 1.5rem;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.bg-white {
  background-color: #fff;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-blue-500 {
  color: #4299e1;
}

.text-gray-500 {
  color: #a0aec0;
}

.text-gray-700 {
  color: #4a5568;
}

.border {
  border-width: 1px;
}

.border-blue-500 {
  border-color: #4299e1;
}

.bg-black {
  background-color: #000;
}

.bg-opacity-50 {
  background-opacity: 0.5;
}

.space-x-4 {
  display: flex;
  gap: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.min-h-screen {
  min-height: 100vh;
}

.selected-btn {
  background-color: var(--el-button-hover-bg-color);
  border-color: var(--el-button-hover-border-color);
  color: var(--el-button-hover-text-color);
  outline: none;
}

.image-container {
  position: relative; /* 使删除图标可以绝对定位 */
}

.image-box {
  width: 150px;
  height: 150px;
  border-radius: 10px; /* 边角圆滑 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* 添加阴影让图片更美观 */
}

.delete-icon {
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离上边距 */
  right: 10px; /* 距离右边距 */
  cursor: pointer; /* 鼠标悬浮时显示为手型 */
  color: red; /* 图标颜色 */
  z-index: 1; /* 确保图标位于图片之上 */
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

.video-list-container {
  max-height: 600px; /* 最大高度为600px */
  overflow-y: auto; /* 当内容超过最大高度时，垂直方向显示滚动条 */
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* 使用rem代替px，确保响应性更好 */
  margin-bottom: 1rem;
}

.video-container {
  position: relative; /* 使删除图标可以绝对定位 */
}

.video-box {
  width: 150px;
  height: 150px;
  border-radius: 10px; /* 边角圆滑 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* 添加阴影让图片更美观 */
  cursor: pointer;
}

.checkbox-icon {
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离上边距 */
  right: 10px; /* 距离右边距 */
  cursor: pointer; /* 鼠标悬浮时显示为手型 */
  z-index: 1; /* 确保图标位于图片之上 */
}
</style>
